//获取元素样式
export function getStyle(node, attr) {
  if (typeof getComputedStyle != 'undefined') {
    var value = getComputedStyle(node, null).getPropertyValue(attr)
    return attr === 'opacity' ? value * 100 : value //兼容不透明度，如果是不透明度，则返回整数方便计算
  } else if (typeof node.currentStyle !== 'undefined') {
    //如果是IE
    if (attr === 'opacity') {
      //兼容不透明度
      return Number(node.currentStyle.getAttribute('filter').match(/(?:opacity[=:])(\d+)/)[1])
    } else {
      return node.currentStyle.getAttribute(attr)
    }
  }
}

//css样式字符串处理
export function cssText(style) {
  var css = ''
  if (typeof style === 'string') {
    css = style
  } else if (typeof style === 'object') {
    for (var i in style) {
      css += i + ':' + style[i] + ';'
    }
  }
  return css
}
