import React from 'react'

const SvgAboutIcon5 = props => (
  <svg
    id='about_icon_5_svg__\u56FE\u5C42_1'
    x={0}
    y={0}
    viewBox='0 0 54 54'
    xmlSpace='preserve'
    enableBackground='new 0 0 54 54'
    {...props}
  >
    <style>{'.about_icon_5_svg__st4{fill:#fff}'}</style>
    <linearGradient
      id='about_icon_5_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={23.174}
      y1={15.196}
      x2={29.392}
      y2={21.414}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#about_icon_5_svg__SVGID_1_)' d='M32.5 18.3l-6.2 6.2-1.6-1.5 4.7-4.7-4.7-4.7 1.6-1.5z' />
    <linearGradient
      id='about_icon_5_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={11.804}
      y1={15.195}
      x2={18.023}
      y2={21.414}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#about_icon_5_svg__SVGID_2_)' d='M14.9 12.1l1.6 1.5-4.7 4.7 4.7 4.7-1.6 1.5-6.2-6.2z' />
    <path d='M17.3 24.2l4.6-12.4 2.1.8L19.3 25l-2-.8zM8.7 40.3h37.4v-9.9H8.7v9.9z' fill='#fd5c1f' />
    <g transform='translate(24 20)'>
      <defs>
        <filter
          id='about_icon_5_svg__Adobe_OpacityMaskFilter'
          filterUnits='userSpaceOnUse'
          x={4.1}
          y={5.1}
          width={24.9}
          height={26}
        >
          <feColorMatrix values='1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0' />
        </filter>
      </defs>
      <mask maskUnits='userSpaceOnUse' x={4.1} y={5.1} width={24.9} height={26} id='about_icon_5_svg__adb_1_'>
        <g filter='url(#about_icon_5_svg__Adobe_OpacityMaskFilter)'>
          <path id='about_icon_5_svg__ada_1_' className='about_icon_5_svg__st4' d='M4.1 5.1H29v26H4.1v-26z' />
        </g>
      </mask>
      <linearGradient
        id='about_icon_5_svg__SVGID_3_'
        gradientUnits='userSpaceOnUse'
        x1={7.418}
        y1={27.204}
        x2={25.671}
        y2={8.951}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path
        d='M16.5 5.1c-.9 0-1.8.1-2.7.3l-.6.1L12 8.8c-.2.6-.8 1-1.5 1h-.2L7 9.3l-.4.4c-1 1.2-1.7 2.6-2.2 4l-.2.6L6.4 17c.4.6.4 1.5 0 2l-2.2 2.7.2.6c.2.8.6 1.6 1 2.2.3.7.8 1.2 1.2 1.8l.4.5 3.4-.6h.2c.6 0 1.3.4 1.5 1l1.1 3.4.7.1c1.8.4 3.7.4 5.5 0l.6-.1 1.1-3.4c.2-.6.8-1 1.5-1h.2l3.3.6.4-.6c.9-1.1 1.7-2.5 2.1-3.9l.2-.6-2.1-2.6c-.4-.6-.4-1.5 0-2l2.2-2.8-.2-.6c-.2-.8-.6-1.5-1-2.1-.3-.7-.8-1.2-1.2-1.8l-.4-.4-3.4.4c-.7 0-1.4-.4-1.7-1.1l-1.1-3.3-.7-.1c-.9-.1-1.8-.2-2.7-.2m0 2.2c.6 0 1.1 0 1.7.1l.7 2c.5 1.5 2.1 2.6 3.8 2.6h.2l2.3-.3.6.9.1.1.1.1c.2.3.4.7.6 1.1l-1.5 1.8c-1.1 1.4-1.1 3.4 0 4.8l1.3 1.6c-.3.8-.7 1.6-1.2 2.3l-2-.3h-.6c-1.6 0-3.1 1-3.5 2.5l-.7 2.2c-1.1.2-2.3.2-3.4 0l-.7-2.1c-.5-1.5-2-2.5-3.5-2.5h-.6l-2.2.4c-.2-.3-.4-.6-.5-.9l-.1-.1-.1-.1c-.3-.5-.5-.9-.7-1.3l1.5-1.8c1.1-1.4 1.1-3.4 0-4.8l-1.4-1.7c.3-.9.7-1.6 1.2-2.2l2.1.3h.6c1.6 0 3-1 3.5-2.5l.8-2.2h1.6'
        mask='url(#about_icon_5_svg__adb_1_)'
        fill='url(#about_icon_5_svg__SVGID_3_)'
      />
    </g>
    <linearGradient
      id='about_icon_5_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={36.176}
      y1={38.092}
      x2={44.97}
      y2={38.092}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M40.6 33.7c-2.4 0-4.4 2-4.4 4.4 0 2.4 2 4.4 4.4 4.4 2.4 0 4.4-2 4.4-4.4 0-2.4-2-4.4-4.4-4.4m0 2.2c1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2'
      fill='url(#about_icon_5_svg__SVGID_4_)'
    />
    <defs>
      <filter
        id='about_icon_5_svg__Adobe_OpacityMaskFilter_1_'
        filterUnits='userSpaceOnUse'
        x={1}
        y={2.9}
        width={48.4}
        height={44}
      >
        <feColorMatrix values='1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={1} y={2.9} width={48.4} height={44} id='about_icon_5_svg__add_1_'>
      <g filter='url(#about_icon_5_svg__Adobe_OpacityMaskFilter_1_)'>
        <path id='about_icon_5_svg__adc_1_' className='about_icon_5_svg__st4' d='M1 2.9h48.4v44H1v-44z' />
      </g>
    </mask>
    <linearGradient
      id='about_icon_5_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={-2.847}
      y1={43.038}
      x2={43.321}
      y2={-3.13}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M26.3 44.7H3.2V5.1h44v22h2.2V2.9H1v44h25.3v-2.2z'
      mask='url(#about_icon_5_svg__add_1_)'
      fill='url(#about_icon_5_svg__SVGID_5_)'
    />
  </svg>
)

export default SvgAboutIcon5
