import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import './index.css'
import getCustomOverlay from './custom-overlay'

/**
 * @see http://lbsyun.baidu.com/index.php?title=jspopular3.0
 */
function BaiduMap(props) {
  const id = props.id
  useEffect(() => {
    window.setupbaidumap = () => {
      const BMap = window.BMap
      getCustomOverlay(BMap)
      const point = new BMap.Point(113.920934, 22.519579)
      const map = new BMap.Map(id) // 创建Map实例
      map.centerAndZoom(point, 19) // 初始化地图,设置中心点坐标和地图级别
      map.addControl(new BMap.MapTypeControl()) //添加地图类型控件
      map.setCurrentCity('深圳') // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true)

      const marker = new BMap.Marker(point)
      map.addOverlay(marker)
      const content = `
        <a href="https://map.baidu.com/poi/%E9%98%B3%E5%85%89%E7%A7%91%E5%88%9B%E4%B8%AD%E5%BF%83-B%E5%BA%A7/@12681758.157522524,2557539.77,19z?uid=327d4691071c32cea2a93437&primaryUid=16180199037274847958&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&querytype=detailConInfo&da_src=shareurl" target="_blank"  rel="nofollow noopener noreferrer external">
          <span>深圳市南山区商华路2号</span><br>
          <span>阳光科创中心B座17层</span>
        </a>
      `
      //实例化覆盖物对象
      const myCustomOverlay = new BMap.CustomOverlay({
        point: point,
        content: `${content}`,
        boxClass: 'my-baidu-box',
        boxStyle: {
          position: 'absolute',
        },
        zIndex: 1,
      })

      //地图缩放重新计算覆盖物位置
      map.addEventListener('zoomend', function() {
        myCustomOverlay.draw()
      })

      //把自定义覆盖物添加到地图
      map.addOverlay(myCustomOverlay)
    }
    // eslint-disable-next-line
  }, [])
  return (
    <React.Fragment>
      <Helmet>
        <script
          src='//api.map.baidu.com/api?v=3.0&ak=rwFkmSu6SalBAr6ZLmzV82NBDGGT7ncq&callback=setupbaidumap'
          async='true'
        />
      </Helmet>
      <div id={id} style={{ width: '100%', height: '100%' }} />
    </React.Fragment>
  )
}

export default React.memo(BaiduMap)

BaiduMap.propTypes = {
  id: PropTypes.string,
}

BaiduMap.defaultProps = {
  id: 'baidu-map',
}
