import React from 'react'

const SvgAboutIcon3 = props => (
  <svg id='about_icon_3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 52 52' xmlSpace='preserve' {...props}>
    <style>{'.about_icon_3_svg__st0{fill:#fd5c1f}'}</style>
    <path id='about_icon_3_svg__Fill-1' className='about_icon_3_svg__st0' d='M26 50V11.4l-11 5.9V50z' />
    <linearGradient
      id='about_icon_3_svg__Fill-2_1_'
      gradientUnits='userSpaceOnUse'
      x1={-2.625}
      y1={49.375}
      x2={37.375}
      y2={9.375}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      id='about_icon_3_svg__Fill-2'
      d='M28 0L0 13.5V52h28V0zM2 15.2L26 3.8V50H2V15.2z'
      fill='url(#about_icon_3_svg__Fill-2_1_)'
    />
    <linearGradient
      id='about_icon_3_svg__Fill-3_1_'
      gradientUnits='userSpaceOnUse'
      x1={35.5}
      y1={16.5}
      x2={44.5}
      y2={7.5}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path id='about_icon_3_svg__Fill-3' fill='url(#about_icon_3_svg__Fill-3_1_)' d='M46 9H30v2h14v8h2z' />
    <linearGradient id='about_icon_3_svg__Fill-4_1_' gradientUnits='userSpaceOnUse' x1={12} y1={19} x2={21} y2={19}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path id='about_icon_3_svg__Fill-4' fill='url(#about_icon_3_svg__Fill-4_1_)' d='M12 20h9v-2h-9z' />
    <linearGradient id='about_icon_3_svg__Fill-5_1_' gradientUnits='userSpaceOnUse' x1={9} y1={28} x2={21} y2={28}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path id='about_icon_3_svg__Fill-5' fill='url(#about_icon_3_svg__Fill-5_1_)' d='M9 29h12v-2H9z' />
    <linearGradient id='about_icon_3_svg__Fill-6_1_' gradientUnits='userSpaceOnUse' x1={9} y1={40} x2={21} y2={40}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path id='about_icon_3_svg__Fill-6' fill='url(#about_icon_3_svg__Fill-6_1_)' d='M9 41h12v-2H9z' />
    <path
      id='about_icon_3_svg__Fill-7'
      className='about_icon_3_svg__st0'
      d='M47 28.3c0 3.6-2.9 6.5-6.5 6.5S34 31.9 34 28.3s2.9-6.5 6.5-6.5 6.5 2.9 6.5 6.5'
    />
    <linearGradient
      id='about_icon_3_svg__Fill-9_1_'
      gradientUnits='userSpaceOnUse'
      x1={32.78}
      y1={55.532}
      x2={48.223}
      y2={40.089}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      id='about_icon_3_svg__Fill-9'
      d='M40.5 36.2c-6.2 0-11.3 6.5-11.3 14.5v1.2h22.5v-1.2c.1-8-5-14.5-11.2-14.5m0 2.5c4.7 0 8.6 5 9 11.3h-18c.4-6.3 4.3-11.3 9-11.3'
      fill='url(#about_icon_3_svg__Fill-9_1_)'
    />
  </svg>
)

export default SvgAboutIcon3
