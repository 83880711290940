import React from 'react'

const SvgAboutIcon1 = props => (
  <svg id='about_icon_1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 52 52' xmlSpace='preserve' {...props}>
    <style>{'.about_icon_1_svg__st3{fill:#fd5c1f}'}</style>
    <linearGradient
      id='about_icon_1_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={-1.272}
      y1={30.283}
      x2={14.668}
      y2={46.224}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M12.1 26.3c-.2.7-.3 1.5-.3 2.3H4.4c-1.2 0-2.1 1-2.1 2.3v15.9h9.6V49H.1V30.9c0-2.5 1.9-4.5 4.3-4.5h7.7z'
      fill='url(#about_icon_1_svg__SVGID_1_)'
    />
    <linearGradient
      id='about_icon_1_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={36.62}
      y1={29.572}
      x2={53.984}
      y2={46.935}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M39.9 26.3h7.7c2.4 0 4.3 2 4.3 4.5V49H40.1v-2.3h9.6V30.9c0-1.3-1-2.3-2.1-2.3h-7.5c0-.8 0-1.5-.2-2.3z'
      fill='url(#about_icon_1_svg__SVGID_2_)'
    />
    <linearGradient
      id='about_icon_1_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={13.363}
      y1={25.902}
      x2={38.637}
      y2={51.176}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M31.7 21.8H20.3c-3.8 0-6.8 3.1-6.8 6.8v22.7h25V28.6c0-3.8-3.1-6.8-6.8-6.8zM36.2 49H15.8V28.6c0-2.5 2-4.5 4.5-4.5h11.4c2.5 0 4.5 2 4.5 4.5V49z'
      fill='url(#about_icon_1_svg__SVGID_3_)'
    />
    <circle className='about_icon_1_svg__st3' cx={8.8} cy={18.4} r={5.7} />
    <circle className='about_icon_1_svg__st3' cx={43.2} cy={18.4} r={5.7} />
    <linearGradient
      id='about_icon_1_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={26}
      y1={1.313}
      x2={26}
      y2={19.494}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M26 19.5c-5 0-9.1-4.1-9.1-9.1S21 1.3 26 1.3s9.1 4.1 9.1 9.1-4.1 9.1-9.1 9.1zm0-15.9c-3.8 0-6.8 3.1-6.8 6.8s3.1 6.8 6.8 6.8 6.8-3.1 6.8-6.8c0-3.8-3-6.8-6.8-6.8z'
      fill='url(#about_icon_1_svg__SVGID_4_)'
    />
  </svg>
)

export default SvgAboutIcon1
