import styled, { css } from 'styled-components'
import { Wrapper } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'

//最外层盒子
export const MainContainer = styled.div`
  width: 100%;

  ${p => p.theme.Hack.desktopEn`
     h1,h2 {
      font-size: 36px !important;
      font-weight: 500 !important;
    }
  `}
  ${Media.phone`
    h2 {
      font-size: .4rem !important;
      font-weight: 500;
    }
  `}
`

//第一屏样式(准备好加入镭速了吗？)
export const FirstBox = styled.div`
  ${({ theme: { color } }) => css`
    width: 100%;
    height: 500px;
    background: ${color.panel};
  `}
`

export const FirstContentBox = styled(Wrapper)`
  width: 1200px;
  height: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${Media.phone`
    flex-wrap: wrap;
  `}
`

export const FirstTextBox = styled.div`
  ${({ theme: { color, typography } }) => css`
    width: 522px;
    h1 {
      font-size: ${typography.h2};
      color: ${color.primary};
    }
    p {
      margin-top: 33px;
      font-size: ${typography.h4};
      color: ${color.text};
    }
    ${Media.phone`
      text-align: center;
      h1 {
        font-size: .48rem;
        margin: .9rem 0 .3rem 0;
      }
      p {
        font-size: .3rem;
        margin: 0 0 .8rem 0;
      }
    `}
  `}
`

export const FirstImgBox = styled.div`
  width: 574px;
  height: 400px;
`

//第二屏样式(公司简介)
export const SecondBox = styled(Wrapper)``

export const SecondTextBox = styled.div`
  ${({ theme: { color, typography } }) => css`
    margin: 0px auto;
    padding: 80px 0 70px 0;
    h2 {
      text-align: center;
      font-size: ${typography.h2};
      color: ${color.primary};
      margin: 0 0 40px 0;
    }
    p {
      font-size: ${typography.text};
      color: ${color.text};
      margin: 0 0 25px 0;
    }
    ${Media.phone`
      width: initial;
      padding: .9rem 0;
      h2 {
        font-size: .4rem;
        margin-bottom: .5rem;
      }
      p {
        font-size: .24rem;
        line-height: .4rem;
        margin-bottom: .3rem;
      }
    `}
  `}
`

//第三屏样式(文化价值观)
export const ThirdBox = styled.div`
  ${({ theme: { color } }) => css`
    width: 100%;
    height: 760px;
    background: ${color.panel};
    ${Media.phone`
      width: initial;
      height: initial;
      padding: 0 0.6rem;
    `}
  `}
`

export const CultureContentBox = styled.div`
  width: 1200px;
  ${({ theme: { color, typography } }) => css`
    padding-top: 80px;
    margin: 0px auto;
    h2 {
      text-align: center;
      font-size: ${typography.h2};
      color: ${color.primary};
      margin: 0 0 50px 0;
    }
    ${Media.phone`
      width: initial;
      padding-top: .8rem;
    `}
  `}
`
export const CultureItemsBox = styled.div`
  ${({ theme: { color, typography, spacing } }) => css`
    width: 1200px;
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    h3 {
      margin: 22px auto 18px;
      height: 23px;
      font-size: ${typography.h3};
      color: ${color.primary};
      line-height: 23px;
    }
    p {
      margin: 2px auto 0;
      font-size: ${typography.textSmall};
      color: ${color.text};
      text-indent: -10px;
      margin-left: 10px;
    }
    ${Media.phone`
      width: initial;
      height: initial;
      > div {
        width: initial;
        height: initial;
        margin: 0 0 .5rem 0;
      }
      h3 {
        font-size: .36rem;
        margin: .3rem 0;
        text-align: center;
      }
      p {
        font-size: .24rem;
        text-align: center;
      }
    `}
  `}
`
export const CultureImgBox = styled.div`
  text-align: center;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px rgba(37, 42, 58, 0.16);
  border-radius: 50%;
  :hover {
    box-shadow: 0px 0px 30px 0px rgba(37, 42, 58, 0.16);
  }
  svg {
    height: 50px;
    width: 54px;
  }
  ${Media.phone`
    width: 1.4rem;
    height: 1.4rem;
    line-height: 1.4rem;
    margin: 0 auto;
    svg {
      width: .7rem;
      height: .7rem;
    }
    h3 {
    }
  `}
`

export const CultureItemBoxOne = styled.div`
  width: 510px;
  height: 227px;
  margin-bottom: 46px;
`
export const CultureItemBoxTwo = styled.div`
  width: 410px;
  height: 227px;
  margin-bottom: 46px;
`
export const CultureItemBoxThree = styled.div`
  width: 280px;
  height: 227px;
  /* white-space: nowrap; */
`

//第四屏样式(团队介绍)
export const FourBox = styled(Wrapper)`
  width: 100%;
  background: rgb(255, 255, 255);
  ${Media.phone`
    width:initial;
    height: initial;
  `}
`
export const TeamBox = styled.div`
  ${({ theme: { typography } }) => css`
    width: 1200px;
    padding: 80px 0;
    margin: 0 auto;
    h2 {
      margin: 0 0 30px 0;
      text-align: center;
      font-size: ${typography.h2};
      font-weight: 400;
      color: rgb(37, 42, 58);
    }
    ${Media.phone`
      width:initial;
      padding-top: .8rem;
    `}
  `}
`
export const TeamContentBox = styled.div`
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${Media.phone`
    width: initial;
    flex-wrap: wrap;
  `}
`
export const TeamContentTextBox = styled.div`
  ${({ theme: { color, typography } }) => css`
    width: 591px;
    p {
      margin: 0 0 20px 0;
      font-size: ${typography.text};
      color: ${color.text};
    }
    ${Media.phone`
      width: initial;
      p {
        font-size: .24rem;
        margin-bottom: .4rem;
      }
    `}
  `}
`

export const TeamContentImgBox = styled.div`
  width: 464px;
  height: 300px;
  ${Media.phone`
    width: 100%;
    height: initial;
  `}
`

//第五屏样式(联系我们)
export const FiveBox = styled(Wrapper)`
  width: 100%;
  height: 620px;
  background: rgb(255, 255, 255);
  ${Media.phone`
    height: initial;
    padding-bottom: 1rem;
  `}
  ${p => p.theme.Hack.en`
    display:none;
  `}
`

export const ContactBox = styled.div`
  ${({ theme: { color, typography, spacing } }) => css`
    width: 1200px;
    margin: 0 auto;
    h2 {
      margin: 0 0 60px 0;
      text-align: center;
      font-size: ${typography.h2};
      font-weight: 400;
      color: ${color.primary};
    }
    ${Media.phone`
      width: initial;
    `}
  `}
`
export const ContactContentBox = styled.div`
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${Media.phone`
    width: initial;
    flex-wrap: wrap;
  `}
`
export const ContactContentLocationBox = styled.div`
  width: 600px;
  height: 354px;
  iframe {
    width: 100%;
    height: 100%;
  }
  ${Media.phone`
    width: 100%;
    height: 4rem;

  `}
`

export const ContactContentTextBox = styled.div`
  width: 427px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${Media.phone`
    width: 100%;
  `}
`

export const ContactContentTextBoxOne = styled.div`
  ${({ theme: { color, typography } }) => css`
    width: 260px;
    font-size: ${typography.h3};
    color: ${color.primary};
    ${Media.phone`
      margin-top: .8rem;
      font-size: .36rem;
    `}
  `}
`

export const ContactContentTextBoxTwo = styled.div`
  ${({ theme: { color, typography } }) => css`
    width: 225px;
    font-size: ${typography.text};
    font-weight: 400;
    color: ${color.text};
    line-height: 30px;
    margin: 40px 0 30px 0;
    p {
      margin: 0;
    }
    ${Media.phone`
      font-size: .24rem;
      line-height: .4rem;
    `}
  `}
`
export const ContactContentTextBoxThree = styled.div`
  ${({ theme: { color, typography } }) => css`
    white-space: normal;
    width: 435px;
    font-size: ${typography.text};
    font-weight: 400;
    color: ${color.text};
    p {
      margin: 0;
    }
    ${Media.phone`
      width: 100%;
      p {
        font-size: .24rem;
      }
    `}
  `}
`
