import { getStyle, cssText } from './dom'

/**
 * @see copy from  https://blog.csdn.net/lihefei_coder/article/details/76596137
 * maybe I should use a class? well, never mind
 */
export default function getCustomOverlay(BMap) {
  //创建自定义覆盖物构造函数
  function CustomOverlay(options) {
    this.point = options.point //覆盖物坐标点
    this.content = options.content || '' //覆盖内容
    this.zIndex = options.zIndex || 1 //覆盖物内容层级
    this.boxClass = options.boxClass || '' //覆盖物容器class名称
    const boxStyle = options.boxStyle ? { position: 'absolute', ...options.boxStyle } : { position: 'absolute' }
    this.boxStyle = cssText(boxStyle) //覆盖物容器样式
  }

  //把百度地图遮盖物对象赋值给构造函数原型对象
  CustomOverlay.prototype = new BMap.Overlay()

  //初始化创建覆盖物
  CustomOverlay.prototype.initialize = function(map) {
    this.map = map
    this.box = document.createElement('div')
    this.box.className = this.boxClass
    this.box.style.cssText = this.boxStyle
    this.box.innerHTML = this.content

    map.getPanes().labelPane.appendChild(this.box)
    return this.box
  }

  //地图拖动调用事件
  CustomOverlay.prototype.draw = function() {
    const pixel = this.map.pointToOverlayPixel(this.point)
    this.box.style.left =
      pixel.x -
      parseInt(getStyle(this.box, 'width')) / 2 -
      (parseInt(getStyle(this.box, 'padding-left')) + parseInt(getStyle(this.box, 'padding-right'))) / 2 +
      'px'
    this.box.style.bottom = 26 - pixel.y + 'px'
  }

  BMap.CustomOverlay = CustomOverlay
}
