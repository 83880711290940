import React, { PureComponent } from 'react'
import Layout from '../components/layout'
import { InviteBanner } from '../components/banners'
import { Swiper } from '@raysync/common/components'
import { LocaleService } from '@raysync/common/services'
import intl from 'react-intl-universal'
import BaiduMap from '../components/baidu-map'

import {
  MainContainer,
  FirstBox,
  FirstContentBox,
  FirstImgBox,
  FirstTextBox,
  SecondBox,
  SecondTextBox,
  ThirdBox,
  CultureContentBox,
  CultureItemsBox,
  CultureItemBoxOne,
  CultureItemBoxTwo,
  CultureItemBoxThree,
  CultureImgBox,
  FourBox,
  TeamBox,
  TeamContentBox,
  TeamContentTextBox,
  TeamContentImgBox,
  FiveBox,
  ContactBox,
  ContactContentBox,
  ContactContentLocationBox,
  ContactContentTextBox,
  ContactContentTextBoxOne,
  ContactContentTextBoxTwo,
  ContactContentTextBoxThree,
} from '../style/about-us.atom'

import FirstImg from '@raysync/common/components/svg/about/about-01'
import IconOne from '@raysync/common/components/svg/about/about-icon-1'
import IconTwo from '@raysync/common/components/svg/about/about-icon-2'
import IconThree from '@raysync/common/components/svg/about/about-icon-3'
import IconFour from '@raysync/common/components/svg/about/about-icon-4'
import IconFive from '@raysync/common/components/svg/about/about-icon-5'
import SecondImg from '@raysync/common/components/svg/about/about-02'

class About extends PureComponent {
  render() {
    const { location } = this.props

    return (
      <Layout location={location} showBanner={LocaleService.isForeign} pageType='about'>
        <MainContainer>
          {/* 第一屏 (准备好加入镭速了吗？)*/}
          <FirstBox>
            <FirstContentBox>
              <FirstTextBox>
                <h1>{intl.get('about.ready.title')}</h1>
                <p>{intl.get('about.ready.description')}</p>
              </FirstTextBox>
              <FirstImgBox>
                <FirstImg />
              </FirstImgBox>
            </FirstContentBox>
          </FirstBox>
          {/* 第二屏(公司简介) */}
          <SecondBox>
            <SecondTextBox>
              <h2>{intl.get('about.company.title')}</h2>
              <p>{intl.get('about.company.description1')}</p>
              <p>{intl.get('about.company.description2')}</p>
              <p>{intl.get('about.company.description3')}</p>
              <p>{intl.get('about.company.description4')}</p>
            </SecondTextBox>
          </SecondBox>
          {/* 第三屏(文化价值观) */}
          <ThirdBox>
            <CultureContentBox>
              <h2>{intl.get('about.culture.bigTitle')}</h2>
              <CultureItemsBox>
                <CultureItemBoxOne>
                  <CultureImgBox>
                    <IconOne />
                  </CultureImgBox>
                  <h3>{intl.get('about.culture.smallTitle1')}</h3>
                  <p>• {intl.get('about.culture.description1')}</p>
                  <p>• {intl.get('about.culture.description2')}</p>
                  <p>• {intl.get('about.culture.description3')}</p>
                </CultureItemBoxOne>
                <CultureItemBoxTwo>
                  <CultureImgBox>
                    <IconTwo />
                  </CultureImgBox>
                  <h3>{intl.get('about.culture.smallTitle2')}</h3>
                  <p>• {intl.get('about.culture.description4')}</p>
                  <p>• {intl.get('about.culture.description5')}</p>
                  <p>• {intl.get('about.culture.description6')}</p>
                </CultureItemBoxTwo>
                <CultureItemBoxThree>
                  <CultureImgBox>
                    <IconThree />
                  </CultureImgBox>
                  <h3>{intl.get('about.culture.smallTitle3')}</h3>
                  <p>• {intl.get('about.culture.description7')}</p>
                  <p>• {intl.get('about.culture.description8')}</p>
                  <p>• {intl.get('about.culture.description9')}</p>
                </CultureItemBoxThree>
                <CultureItemBoxOne>
                  <CultureImgBox>
                    <IconFour />
                  </CultureImgBox>
                  <h3>{intl.get('about.culture.smallTitle4')}</h3>
                  <p>• {intl.get('about.culture.description10')}</p>
                  <p>• {intl.get('about.culture.description11')}</p>
                  <p>• {intl.get('about.culture.description12')}</p>
                </CultureItemBoxOne>
                <CultureItemBoxTwo>
                  <CultureImgBox>
                    <IconFive />
                  </CultureImgBox>
                  <h3>{intl.get('about.culture.smallTitle5')}</h3>
                  <p>• {intl.get('about.culture.description13')}</p>
                  <p>• {intl.get('about.culture.description14')}</p>
                  <p>• {intl.get('about.culture.description15')}</p>
                </CultureItemBoxTwo>
              </CultureItemsBox>
            </CultureContentBox>
          </ThirdBox>
          {/* 第四屏(团队介绍)*/}
          <FourBox>
            <TeamBox>
              <h2>{intl.get('about.team.title')}</h2>
              <TeamContentBox>
                <TeamContentTextBox>
                  <p>{intl.get('about.team.description1')}</p>
                  <p>{intl.get('about.team.description2')}</p>
                  <p>{intl.get('about.team.description3')}</p>
                  <p>{intl.get('about.team.description4')}</p>
                  <p>{intl.get('about.team.description5')}</p>
                </TeamContentTextBox>
                <TeamContentImgBox>
                  <SecondImg />
                </TeamContentImgBox>
              </TeamContentBox>
            </TeamBox>
          </FourBox>
          {/* 第五屏(联系我们)*/}
          <FiveBox>
            <ContactBox>
              <h2>{intl.get('about.contact.title')}</h2>
              <ContactContentBox>
                <ContactContentLocationBox>
                  {LocaleService.isForeign ? (
                    <iframe
                      title='map'
                      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.719606431827!2d113.91210931545552!3d22.51470098521257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403ee50f0307c9f%3A0xef502d1bada522fc!2sSunny+Kechuang+Center!5e0!3m2!1sen!2sus!4v1502864612844;'
                      width='600'
                      height='354'
                      frameBorder='0'
                      allowFullScreen
                    />
                  ) : (
                    <BaiduMap />
                  )}
                </ContactContentLocationBox>
                <ContactContentTextBox>
                  <ContactContentTextBoxOne>{intl.get('about.contact.company')}</ContactContentTextBoxOne>
                  <ContactContentTextBoxTwo>
                    <p>{intl.get('about.contact.telephone')}</p>
                    <p>{intl.get('about.contact.email')}</p>
                  </ContactContentTextBoxTwo>
                  <ContactContentTextBoxThree>
                    <p>{intl.get('about.contact.SZ')}</p>
                    <p>{intl.get('about.contact.BJ')}</p>
                    <p>{intl.get('about.contact.SH')}</p>
                    <p>{intl.get('about.contact.CD')}</p>
                  </ContactContentTextBoxThree>
                </ContactContentTextBox>
              </ContactContentBox>
            </ContactBox>
          </FiveBox>
          {!LocaleService.isForeign && (
            <Swiper>
              <InviteBanner type='about' variant='inquire' />
            </Swiper>
          )}
        </MainContainer>
      </Layout>
    )
  }
}
export default About
