import React from 'react'

const SvgAboutIcon4 = props => (
  <svg id='about_icon_4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 52 52' xmlSpace='preserve' {...props}>
    <style>{'.about_icon_4_svg__st1{fill:#fd5c1f}'}</style>
    <linearGradient
      id='about_icon_4_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={466.5}
      y1={-282.261}
      x2={514.5}
      y2={-330.261}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={1} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M466.5-328.3v44h21.2l2.8 1.9 2.8-1.9h21.2v-44h-20.6l-3.4 2.4-3.4-2.4h-20.6z'
      fill='none'
      stroke='url(#about_icon_4_svg__SVGID_1_)'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path className='about_icon_4_svg__st1' d='M490.5-317.3l3-3h13v26l-3 3h-13v-26z' />
    <linearGradient
      id='about_icon_4_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={472.5}
      y1={-305.261}
      x2={496.5}
      y2={-305.261}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#about_icon_4_svg__SVGID_2_)' d='M472.5-306.3h24v2h-24z' />
    <linearGradient
      id='about_icon_4_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={472.5}
      y1={-297.261}
      x2={496.5}
      y2={-297.261}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#about_icon_4_svg__SVGID_3_)' d='M472.5-298.3h24v2h-24z' />
    <linearGradient
      id='about_icon_4_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={472.5}
      y1={-313.261}
      x2={485.5}
      y2={-313.261}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#about_icon_4_svg__SVGID_4_)' d='M472.5-314.3h13v2h-13z' />
    <linearGradient
      id='about_icon_4_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={2}
      y1={49.593}
      x2={50}
      y2={1.593}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={1} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M2 3.6v44h21.2l2.8 1.9 2.8-1.9H50v-44H29.4L26 6l-3.4-2.4H2z'
      fill='none'
      stroke='url(#about_icon_4_svg__SVGID_5_)'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path className='about_icon_4_svg__st1' d='M26 14.6l3-3h13v26l-3 3H26v-26z' />
    <g>
      <linearGradient
        id='about_icon_4_svg__SVGID_6_'
        gradientUnits='userSpaceOnUse'
        x1={8}
        y1={26.593}
        x2={32}
        y2={26.593}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='url(#about_icon_4_svg__SVGID_6_)' d='M8 25.6h24v2H8z' />
      <linearGradient
        id='about_icon_4_svg__SVGID_7_'
        gradientUnits='userSpaceOnUse'
        x1={8}
        y1={34.593}
        x2={32}
        y2={34.593}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='url(#about_icon_4_svg__SVGID_7_)' d='M8 33.6h24v2H8z' />
      <linearGradient
        id='about_icon_4_svg__SVGID_8_'
        gradientUnits='userSpaceOnUse'
        x1={8}
        y1={18.593}
        x2={21}
        y2={18.593}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='url(#about_icon_4_svg__SVGID_8_)' d='M8 17.6h13v2H8z' />
    </g>
  </svg>
)

export default SvgAboutIcon4
