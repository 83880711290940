import React from 'react'

const SvgAboutIcon2 = props => (
  <svg viewBox='0 0 52 52' {...props}>
    <linearGradient
      id='about_icon_2_svg__a'
      gradientUnits='userSpaceOnUse'
      x1={11.973}
      y1={4.068}
      x2={26.202}
      y2={28.713}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M3.2 33.1l8.2 1.4-6.6 6.6C-2.4 30.9-1.5 16.7 7.6 7.6c9.1-9.1 23.3-10 33.5-2.8l-1.6 1.6C30.2 0 17.4.9 9.1 9.1c-6.4 6.5-8.4 15.8-5.9 24z'
      fill='url(#about_icon_2_svg__a)'
    />
    <linearGradient
      id='about_icon_2_svg__b'
      gradientUnits='userSpaceOnUse'
      x1={23.197}
      y1={54.411}
      x2={48.023}
      y2={11.411}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M48.8 18.9l-8.2-1.4 6.6-6.6c7.2 10.2 6.3 24.3-2.8 33.5-9.1 9.1-23.3 10-33.5 2.8l1.6-1.6c9.3 6.4 22.1 5.5 30.4-2.8 6.4-6.4 8.4-15.7 5.9-23.9z'
      fill='url(#about_icon_2_svg__b)'
    />
    <path
      d='M19.5 34.7c-4.2 0-7.6-3.4-7.6-7.6 0-4.2 3.4-7.6 7.6-7.6h.3c1.2-4 5.4-6.3 9.4-5.1 3.3 1 5.5 4 5.4 7.4 3.5-.6 6.9 1.8 7.5 5.3s-1.8 6.9-5.3 7.5c-.7.1-1.4.1-2.2 0v.1H19.5z'
      fill='#fd5c1f'
    />
  </svg>
)

export default SvgAboutIcon2
