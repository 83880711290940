import React from 'react'

const SvgAbout01 = props => (
  <svg
    id='about_01_svg___x34_ef1610a-9e03-48a5-8dcf-c01d7d13df94'
    x={0}
    y={0}
    viewBox='0 0 580 400'
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.about_01_svg__st0,.about_01_svg__st1{fill:#fd5c1f}.about_01_svg__st1{opacity:.1;enable-background:new}.about_01_svg__st3{fill:#444176}.about_01_svg__st4{enable-background:new}.about_01_svg__st4,.about_01_svg__st6{opacity:.1}.about_01_svg__st7{fill:#ced2df}.about_01_svg__st8{fill:#88455e}.about_01_svg__st9{fill:#ec8c9c}.about_01_svg__st13{fill:#ffbdc6}.about_01_svg__st14{fill:#7679b4}.about_01_svg__st15{fill:#a66477}.about_01_svg__st19{fill:#493545}.about_01_svg__st20{opacity:5.000000e-02;enable-background:new}.about_01_svg__st21{fill:#3e3d77}.about_01_svg__st22{fill:#c77485}.about_01_svg__st24,.about_01_svg__st25{fill:none;stroke-miterlimit:10}.about_01_svg__st24{stroke:#535461;stroke-width:2}.about_01_svg__st25{stroke:#fd5c1f}.about_01_svg__st26{fill:#a8a8a8}.about_01_svg__st27{opacity:.2;enable-background:new}.about_01_svg__st28{fill:#ffd037}'
      }
    </style>
    <circle className='about_01_svg__st0' cx={60.4} cy={39.9} r={28.1} />
    <path
      className='about_01_svg__st1'
      d='M56.9 97.2c11.8 26.7-3.3 57.4-21 80.5-5.9 7.8-12.5 15.9-13.3 25.6-1 11.9 7.1 22.8 16.6 30 17.4 13 41.2 17.1 62 10.5 17.9-5.7 33-18.5 51.1-23.7 30.2-8.7 62.3 5.7 88.2 23.6 18.3 12.6 38 28.1 60.1 25.3 15.5-1.9 28.7-12.8 37.7-25.7 4.3-6.2 8.1-13.2 14.4-17.3 3.7-2.5 8.1-3.8 12.5-4.8 40.1-8.6 82.8 12.4 122.4 1.6 26.8-7.3 48.1-28.4 61.4-52.8s19.5-51.8 24.6-79.1c3.7-19.3 6.7-40.3-1.7-58C562.8 14 542.2 2.6 521.5.5c-20.7-2.1-41.5 3.8-60.6 12.1-23.9 10.4-47.3 25-73.3 25.1-17.7 0-34.7-6.8-51.6-12-28.4-8.7-57.9-13-87.2-17-28.1-3.8-56.6-7.4-84.9-5.1-20 1.6-37.7 9.2-57.1 12.8-12.8 2.3-25.5.6-38 4.5-15.3 4.8-31.1 15.9-31.9 33.4-.8 16.6 13.8 28.7 20 42.9z'
    />
    <linearGradient
      id='about_01_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={406.418}
      y1={-45.528}
      x2={406.418}
      y2={204.791}
      gradientTransform='matrix(1 0 0 -1 -125.1 283.24)'
    >
      <stop offset={0} stopColor='gray' stopOpacity={0.25} />
      <stop offset={0.54} stopColor='gray' stopOpacity={0.12} />
      <stop offset={1} stopColor='gray' stopOpacity={0.1} />
    </linearGradient>
    <path
      d='M366.8 120.9c-2.6-6.7-9.2-22.5-13.6-19.4-5.5 4 5.5 21.5 5.5 21.5.3 3.2-5.9 9.9-9.3 13.2v-.4c0 .1-.1.2-.1.4l-.3-.2c-.1.3-.2.5-.4.7v-.7c-.6 2.7-11.8 6.2-11.8 6.2h-.8c-3.1 0-11.2-.7-14.9-6.7-1.9-3.2-4.4-6.1-7.3-8.6-1-.8-2.2-1.2-3.5-1.3-10.4-.7-18.7-5.9-20.4-7l-.3-.2-.1.2c-.4-.6-.8-1.2-1.1-1.8l-.2-.3c5.1-2.6 8.3-7.8 8.4-13.5v-.3c.9-1.6 1.4-3.4 1.4-5.2v-1.8l2.4-2.1c1.1-1 2.2-2 2.8-3.3.2-.5.3-1.1.3-1.7.1-1-.3-2-1.1-2.6-1.8-1.3-4.8 0-6.1-1.7-.6-.8-.5-1.9-.8-2.8-.6-1.9-2.8-2.9-4.8-2.9s-3.9.7-5.9 1.3c-4.1 1.2-8.3 1.6-12.5 1.2-1.1-.2-2.3-.2-3.4.1-1.4.6-2.4 1.8-2.8 3.2-.1.5-.2 1.1-.3 1.7-1.7 1.9-2.6 4.4-2.6 6.9v4.7c0 2.5.9 4.9 2.5 6.8.4 4.4 2.7 8.5 6.3 11v.4h.1c-.1 1.4-.3 2.9-.7 4.3v-.3s-8.5 4.5-17.5 4.6h-1c-1.8-.1-3.5.6-4.8 1.8-2.7 2.5-7.4 6.5-10.3 6.5-.6 0-15.9 3-18.4 3.5l-4.7-4.6-.1.3-.3-.3c-.5 0-1 .1-1.5.2-2.4-2.9-7.3-9.5-8.4-18.5 0 0 10.4-11.9 6.6-23-3.3-9.4-12.3 10.8-14.9 16.9-.4 1.1-.5 2.3-.2 3.4l8.5 27.3c-.8 2.1-1 4.4-.7 6.7h-.1l6.2 7.9s4.7 2 11.9 1.9c5.3 0 10.6-1 15.6-3h1.4l-.3.1h7.4l4.7 14.2c-.2 1.3-2.4 19.3-4.2 23.3-.5 1.3-.8 2.8-.7 4.2.1 2.8.5 5.5 1.3 8.2l4.1-.2-.2.3c-3.5 7.1-9.3 19.8-12.7 31.6l-6 12.1 4.5 54.2h.2l.3.1c.5.1 1.2.2 2.2.3l-.4.6-9.9 16.3s-7 14.4 8.5 11.7c0 0 5.8-1.7 8.1-11.5l10.2-9.6s.5-6.4-2.4-8.9c-.2-2.9-.6-11.5.1-13.6.9-2.5-3.6-31.3-3.6-31.3s16.2-33.6 26-34.4c.7-.1 1.4 0 2.1.3 8.1 2.6 17.8 22.1 20.7 28.2l.1.3v.1l.1.2v.1l.1.2v.1l.1.2.1.2v.1l.1.1V249.6l1.3 37.8.1.1h.1l-.1.8-.6 3.9s7 14.8 6.6 16.7 4.7 9.4 11.9 7.3 2.9-13.8 2.9-13.8l-7-14.4-.3-.6c.7-.4 1.4-.9 2-1.5 0 0-2.1-15.4 0-18.8 2.1-3.3 2.8-18.6 2.8-18.6s-1.5-10.4-4.1-15l-10.7-38.2c3.2 3 5.7 4.2 7.8 4.1h.3c.9 0 1.8-.2 2.6-.7.4-.2.8-.5 1.2-.9l.2-.2c3-2.5-3.4-12.3-7-18.1-3.5-5.6 1.3-20.9 1.7-22 .3-.2.7-.4 1-.6.8-.3 1.6-.5 2.4-.5 3.5.1 7.7 2.1 13 3.1 3.7.6 7.4.9 11.1.9h.2c1.5 0 2.8 0 3.9-.1 1 0 2-.3 2.8-.8.8-.4 1.4-1 2-1.6l9.3-10.5-.2.1c.4-1.2.4-2.5 0-3.7l9.8-16.9c1-2.1 1.2-4.2.5-6z'
      fill='url(#about_01_svg__SVGID_1_)'
    />
    <path
      className='about_01_svg__st3'
      d='M256.7 305.9l-9.9 9.5c-2.3 9.7-7.8 11.3-7.8 11.3-15 2.7-8.2-11.5-8.2-11.5l9.5-16.1 1.2-2s6.8-2.1 11.3-.8c.6.2 1.1.4 1.5.8 2.8 2.5 2.4 8.8 2.4 8.8zM317.6 314.3c-7 2.1-11.9-5.3-11.5-7.2s-6.4-16.4-6.4-16.4l.5-3.8.5-3.6 11.5.8 1.1 2.3 6.7 14.2s4.5 11.7-2.4 13.7z'
    />
    <path
      className='about_01_svg__st4'
      d='M254.4 298.7c-1.1 1.9-9.9.9-14.1.3l1.2-2s6.8-2.1 11.3-.8c.6.2 1.1.4 1.5.8.1 1.1.1 1.7.1 1.7zM313.3 286.4c-5.9 3.9-12.1 1-13.1.5l.5-3.6 11.5.8 1.1 2.3z'
    />
    <path
      d='M314.9 265.8c-2.1 3.3 0 18.5 0 18.5-6.8 6.2-14.8 1.6-14.8 1.6l-1.2-37.2s-13.4-30.7-22.8-29.8c-9.5.8-25.1 33.9-25.1 33.9s4.3 28.4 3.5 30.8c-.8 2.5 0 14.4 0 14.4-1.4 2.5-16.5 0-16.5 0l-4.3-53.5 5.7-11.9c3.3-11.7 8.9-24.2 12.2-31.2 1.7-3.5 2.8-5.6 2.8-5.6l10.9-2.3 35.5-7.4 12.8 46.5c2.5 4.5 3.9 14.8 3.9 14.8s-.6 15.1-2.6 18.4z'
      fill='#565387'
    />
    <g className='about_01_svg__st6'>
      <path d='M303.4 286l-1.2-37.2s-13.4-30.7-22.8-29.8c-.4 0-.8.1-1.2.3 9.2 3.1 20.8 29.6 20.8 29.6l1.2 37.2c2.5 1.3 5.4 1.8 8.2 1.5-1.9-.4-3.5-.9-5-1.6z' />
      <path d='M241.2 298.1l-4.3-53.5 5.8-12c3.3-11.7 8.9-24.2 12.2-31.2 1.7-3.5 2.8-5.6 2.8-5.6l10.9-2.3 32.4-6.7-.2-.6-35.5 7.4-10.9 2.3s-1.1 2.1-2.8 5.6c-3.4 7-8.9 19.5-12.2 31.2l-5.8 11.9 4.3 53.5s8.2 1.3 13.1 1.1c-3.3-.3-6.5-.6-9.8-1.1z' />
    </g>
    <path className='about_01_svg__st7' d='M216.2 132.4s-11.7.6-10.1 13l3.3.4 4.9-5.3 2.5-4.5v-1l-.6-2.6z' />
    <path className='about_01_svg__st4' d='M216.2 132.4s-11.7.6-10.1 13l3.3.4 4.9-5.3 2.5-4.5v-1l-.6-2.6z' />
    <path className='about_01_svg__st7' d='M346.7 136.9s9.7 5.1 7.2 11.1l-3.1 1.2-4.1-2.3-.6-5.3.4-3.7.2-1z' />
    <path className='about_01_svg__st4' d='M346.7 136.9s9.7 5.1 7.2 11.1l-3.1 1.2-4.1-2.3-.6-5.3.4-3.7.2-1z' />
    <path
      className='about_01_svg__st8'
      d='M276.3 83.9h8.2c7 0 12.8 5.7 12.8 12.8 0 7-5.7 12.8-12.8 12.8h-8.2c-7 0-12.8-5.7-12.8-12.8.1-7.1 5.8-12.8 12.8-12.8z'
    />
    <path
      className='about_01_svg__st9'
      d='M216.1 134.2l-7.2 11.5-.6-2-9.8-31.9c-.3-1.1-.3-2.3.2-3.3 2.5-6 11.2-26 14.4-16.7 3.7 10.9-6.4 22.6-6.4 22.6 1.1 9.5 6.5 16.4 8.6 18.8.5.6.8 1 .8 1zM345.1 139.1s11.3-10.7 10.9-15c0 0-10.7-17.3-5.3-21.2 4.2-3.1 10.7 12.5 13.2 19.1.7 1.8.5 3.8-.4 5.4L350 151.3l-4.9-12.2zM271.9 109.3s2.1 13.2-3.9 18.1c-6 4.9 17.1 4.9 17.1 4.9l8.2-7.6s-9.5-8.8-6.6-15.4h-14.8z'
    />
    <path
      className='about_01_svg__st4'
      d='M271.5 124.3s8.8 6.6 17.7-.8l14.4 29.8-.8 36.4s-29.8 15.4-42.2 6c-12.3-9.5-2.1-14.4-2.1-14.4l-2.9-28 3.1-18.1s5.6-8.6 6-8.6 6.8-2.3 6.8-2.3z'
    />
    <path
      d='M271.5 123.9s8.8 6.6 17.7-.8l14.4 29.8-.8 36.4s-29.8 15.4-42.2 6c-12.3-9.5-2.1-14.4-2.1-14.4l-2.9-28 3.1-18.1s5.6-8.6 6-8.6 6.8-2.3 6.8-2.3z'
      fill='#ebf1f6'
    />
    <path
      className='about_01_svg__st4'
      d='M271.5 120.8s-8.4 4.8-17.5 4.5c-1.7-.1-3.4.6-4.7 1.8-2.6 2.5-7.1 6.4-9.9 6.4-.6 0-18.1 3.5-18.1 3.5l-4.6-4.6s-6.9 13.5-10.2 12.9l6 7.8s11.3 4.9 26.1-1h8.6l4.5 14s-2.3 18.9-4.1 23 .6 12.1.6 12.1l16.2-.6s.8-8 2.1-9.1c1.2-1-4.3-36.4-4.3-36.4s-3.7-20 9.9-27.2l-.6-7.1z'
    />
    <path
      className='about_01_svg__st4'
      d='M265.3 193.6c-.6 2.4-1 4.9-1.3 7.4l-12.4.5c1.7-3.5 2.8-5.6 2.8-5.6l10.9-2.3zM216.1 134.2l-7.2 11.5-.6-2c2.6-2.6 5.7-8.1 7-10.4.5.5.8.9.8.9z'
    />
    <path
      className='about_01_svg__st7'
      d='M271.5 120.8s-8.8 4.8-17.9 4.5c-1.7-.1-3.4.6-4.7 1.8-2.6 2.5-7.1 6.4-9.9 6.4-.6 0-18.1 3.5-18.1 3.5l-4.6-4.6s-6.9 13.5-10.2 12.9l6 7.8s11.3 4.9 26.1-1h8.6l4.5 14s-2.3 18.9-4.1 23 .6 12.1.6 12.1l16.2-.6s.8-8 2.1-9.1-4.3-36.4-4.3-36.4-3.7-20 9.9-27.2l-.2-7.1z'
    />
    <path
      className='about_01_svg__st4'
      d='M289.2 119.5s8.4 6.4 19.6 7.1c1.2.1 2.4.5 3.4 1.3 2.8 2.4 5.2 5.3 7.1 8.5 4.5 7.4 15.6 6.6 15.6 6.6s10.8-3.4 11.4-6c0 0 .3 14.5 7.1 11l-8.9 10.3c-1.1 1.3-2.8 2.1-4.5 2.2-3.4.1-9.2.2-14.1-.8-7.4-1.4-12.8-4.9-16.7-1.9 0 0-5.1 16-1.6 21.8s9.7 15.4 6.8 17.9-6.4 2.3-12.1-3.7c-5.8-6-6.4-16-6.4-16l1.4-25.9s-5.6-19.5-10.9-23.4l2.8-9z'
    />
    <path
      className='about_01_svg__st4'
      d='M354.2 147.7l-9 10.3c-.6.7-1.3 1.2-2.2 1.6-.7.3-1.5.5-2.3.6-3.4.1-9.2.2-14.1-.8-6.9-1.3-12.1-4.5-15.9-2.4-.3.1-.5.3-.8.5 0 0-5.1 16-1.6 21.8s9.7 15.4 6.8 17.9c-.5.4-1 .8-1.6 1-2.6 1.2-5.8.2-10.5-4.7-5.8-6-6.4-16-6.4-16l1.4-25.9s-5.6-19.5-10.9-23.4l2.4-8.4c1.6 1.1 9.7 6.3 19.7 6.9 1.2.1 2.4.5 3.4 1.3 2.8 2.4 5.2 5.3 7.1 8.5 4.5 7.4 15.6 6.6 15.6 6.6 2.7-.8 5.3-1.9 7.8-3.1 1.6-.8 3-1.6 3.6-2.4.2-.2.3-.5.4-.7 0-.1.3 14.3 7.1 10.8z'
    />
    <path
      className='about_01_svg__st7'
      d='M353.8 147.9l-8.9 10.3c-.5.6-1.1 1-1.7 1.4-.8.5-1.8.7-2.7.8-3.4.1-9.2.2-14.1-.8-6.7-1.3-11.7-4.3-15.5-2.6-.4.2-.8.4-1.2.7 0 0-5.1 16-1.6 21.8s9.7 15.4 6.8 17.9c-.4.3-.8.6-1.2.8-2.7 1.5-6 .6-10.9-4.5-5.8-6-6.4-16-6.4-16l1.4-25.9s-5.6-19.5-10.9-23.4l2.5-8.8.3.2c1.6 1.1 9.7 6.3 19.7 6.9 1.2.1 2.4.5 3.4 1.3 2.8 2.4 5.2 5.3 7.1 8.5 4.5 7.4 15.6 6.6 15.6 6.6 2.7-.8 5.3-1.9 7.8-3.1 1.9-1 3.4-2 3.6-3v.5c-.1 2.5.8 13.5 6.9 10.4z'
    />
    <path
      className='about_01_svg__st4'
      d='M286.9 109.6h-15.1c.3 2.4.4 4.8.3 7.3 4.7 3.3 10.8 3.8 16 1.1-1.5-2.7-2.4-5.8-1.2-8.4z'
    />
    <circle className='about_01_svg__st9' cx={280.9} cy={104.3} r={15} />
    <path
      className='about_01_svg__st4'
      d='M277.8 101.7c1.7-.2 3.3-.8 5-.5 1.5.3 2.8 1.3 4.3 1.7 2.9.6 5.6-1.5 7.8-3.5l4.7-4.3c1-.9 2.1-1.9 2.7-3.3.5-1.3.4-3-.8-3.8-1.7-1.2-4.7 0-5.9-1.7-.6-.8-.5-1.8-.8-2.7-.6-1.9-2.7-2.9-4.6-2.9-1.9 0-3.8.7-5.7 1.2-3.9 1.1-8 1.5-12.1 1.2-1.1-.2-2.2-.2-3.3.1-1.3.6-2.3 1.8-2.7 3.2-.9 3.3.7 8.6 2.3 11.5 1.5 2.9 5.9 4.1 9.1 3.8z'
    />
    <path
      className='about_01_svg__st8'
      d='M277.8 101.2c1.7-.2 3.3-.8 5-.5 1.5.3 2.8 1.3 4.3 1.7 2.9.6 5.6-1.5 7.8-3.5l4.7-4.3c1-.9 2.1-1.9 2.7-3.3.5-1.3.4-3-.8-3.8-1.7-1.2-4.7 0-5.9-1.7-.6-.8-.5-1.8-.8-2.7-.6-1.9-2.7-2.9-4.6-2.9-1.9 0-3.8.7-5.7 1.2-3.9 1.1-8 1.5-12.1 1.2-1.1-.2-2.2-.2-3.3.1-1.3.6-2.3 1.8-2.7 3.2-.9 3.3.7 8.6 2.3 11.5 1.5 2.9 5.9 4.2 9.1 3.8z'
    />
    <path
      className='about_01_svg__st4'
      d='M251.6 166.1s3.3.4 4.9 2.5c1.6 2.1 4.5 2.7 4.5 2.7M222 139s2.7 6 1 6.6c-1.7.6-1 4.9-1 4.9'
    />
    <g className='about_01_svg__st6'>
      <path d='M222.9 136.9s17.5-3.5 18.1-3.5c2.8 0 7.4-3.9 9.9-6.4.8-.8 1.8-1.3 2.8-1.6h-.2c-1.7-.1-3.4.6-4.7 1.8-2.6 2.5-7.1 6.4-9.9 6.4-.5 0-11.7 2.2-16.2 3.1l.2.2zM271.5 121.7v-.8c-4.2 2.1-8.7 3.6-13.4 4.3 4.6-.4 9.2-1.6 13.4-3.5zM249.9 201.1s-2.5-8-.6-12.1 4.1-23 4.1-23l-4.5-14h-8.7l-.5.2h7.1l4.5 14s-2.3 18.9-4.1 23 .6 12.1.6 12.1l16.2-.6v-.1l-14.1.5z' />
    </g>
    <g className='about_01_svg__st6'>
      <path d='M314.9 194.2c-.4.3-.8.6-1.2.8-2.7 1.5-6 .6-10.9-4.5-4.6-4.7-5.9-12.1-6.3-14.9l-.1 2.1s.6 10.1 6.4 16c5 5.2 8.3 6 10.9 4.5.4-.2.8-.5 1.2-.8.9-.7.9-2.1.5-3.9-.2.4-.3.6-.5.7zM350.8 148.2L345 155c-.5.6-1.1 1-1.7 1.4-.8.5-1.8.7-2.7.8-3.4.1-9.2.2-14.1-.8-6.7-1.3-11.7-4.3-15.5-2.6-.4.2-.8.4-1.2.7 0 0-3.3 10.4-2.8 17.4.4-6.6 2.8-14.1 2.8-14.1.4-.3.8-.5 1.2-.7 3.8-1.7 8.8 1.3 15.5 2.6 4.9 1 10.7.9 14.1.8 1 0 1.9-.3 2.7-.8.7-.4 1.2-.8 1.7-1.4l9-10.3c-1 .5-2.1.6-3.2.2z' />
    </g>
    <linearGradient
      id='about_01_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={213.248}
      y1={60.796}
      x2={213.248}
      y2={60.929}
      gradientTransform='matrix(1 0 0 -1 -125.1 283.24)'
    >
      <stop offset={0} stopColor='gray' stopOpacity={0.25} />
      <stop offset={0.54} stopColor='gray' stopOpacity={0.12} />
      <stop offset={1} stopColor='gray' stopOpacity={0.1} />
    </linearGradient>
    <path d='M87.9 222.3c.1.1.4.2.6.1-.3 0-.4 0-.6-.1z' fill='url(#about_01_svg__SVGID_2_)' />
    <linearGradient
      id='about_01_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={205.01}
      y1={-10.453}
      x2={205.01}
      y2={191.49}
      gradientTransform='matrix(1 0 0 -1 -125.1 283.24)'
    >
      <stop offset={0} stopColor='gray' stopOpacity={0.25} />
      <stop offset={0.54} stopColor='gray' stopOpacity={0.12} />
      <stop offset={1} stopColor='gray' stopOpacity={0.1} />
    </linearGradient>
    <path
      d='M149.5 122.1c-1.1-.3-2.6-.8-4.5-1.5 1.9-2.5 3.4-5.4 4.2-8.4l-.2.1c.1-.2.1-.4.2-.6-3.6 1.5-7.6-1-10.1-4.2s-4.2-7.1-7-10c-1.2-1.2-2.4-2.2-3.7-3.2-.8-.6-1.7-1.3-2.7-1.3-1.6 0-2.9 1.5-4.4 2-1 .2-2.1.2-3.1-.1-4-.9-7.9-2.6-12-3.1-2.2-.3-4.4 0-6.5.7-2.1.8-3 2.3-4.7 3.6-1.8 1.3-5.4 1.3-7.5 1.5-2.5.4-5.1.5-7.6.4-1.5-.1-3-.1-4.4.2-3.1.7-5.5 3.1-7.8 5.4-.6.5-1.1 1.2-1.3 2v.7c0 .6.1 1.2.3 1.8.7 2.1 1.7 4.1 3 5.9.9 1.3 2 2.6 2.6 4.2.3.8.4 1.6.4 2.4 0 .9-.1 1.7-.3 2.6-.4 2.4-1.1 4.7-2 6.9-.6 1.1-1 2.4-1.2 3.6v.8c-.2 4 3.9 6.9 4.7 10.8.1.3.1.7.1 1-.1 2.5-1.5 4.9-3.6 5.9.2.1.4.1.6.2l-.6.3c.4.1.8.3 1.2.4-2.4 2.1-4.4 4.5-5.9 7.3-7 13-54.4 38.5-54.4 38.5l.7.4-.6.4c-4.1 2.7-13.7 10.7-5 20.7 0 0 6.8-10.7 11.5-15.3l.6-.6c.2.3.3.7.2 1.1l31.2-17.5s11.1-7.8 14.2-9.5c2.2-1.4 4.5-2.4 7-3l-.4.2c1.2-.3 2.4-.5 3.6-.5 5.5.1 11.5 4.1 13.5 20.1 2.6 21.4 1.4 25.3.6 25.8-.1 0-.2 0-.3-.1 0 0 .1 0 .1.1-.1 0-.2 0-.3-.1.1.1.3.2.5.1h.7v.6s-.2.1 0 .1c-.1 1.2-.1 2.5 0 3.9-2.7.2-5.5.3-7.9.5l.2-1.1.1-.5c2.1-.2 4.1-.8 4.3-2.5.5-3.7-4.6-5.8-9-6-4.4-.2-30.6 1.5-30.6 1.5s-3.9 5.1 8.6 6.7l1.2.6c4.9 2.8 11.5 7.9 14.7 10.4l.4.4-1.4 1.2c7.7 1.7 15.2 31.3 19.3 44.7 4.1 13.5 13.1 13 20.6 11 7-1.9 7.9-22.2 8-24.7v-.3l12.4 15.4.9 1.2c.4.2.7.4 1.1.5 9.4 4.5 19.8-4.1 16.9-13.9-3-9.9-7.6-24.5-10.7-30.7-2.6-5.2-5.9-13.8-8-19.1.3-.1.5-.2.8-.4 0 0 0-.2-.1-.7l.1-.1s-3-15.6-3.2-26.6v-.6-.4c2.2-.5 3.7-.7 3.7-.7v-.5l-.3.1 1.3-14.3s4.6-9.8 1.1-13.8c-1.1-1.2-1.8-2.7-2.1-4.2-.6-3.7 0-10 8.2-15.2 12.6-8 17.5-14.5 17.5-14.5s0-4.8-7.7-7.1zm-25.1 10v-6.6c2.3-.1 4.7.2 7.1 0 1.2-.1 2.3-.3 3.4-.6l.6.4c-3.4 2.6-7.1 4.9-11.1 6.8zM88.7 235.9c.9.6 1.9 1.3 2.8 2 .6 3.3 1.1 5.6 1.1 5.7-1.1-2.7-2.4-5.3-3.9-7.7z'
      fill='url(#about_01_svg__SVGID_3_)'
    />
    <path
      className='about_01_svg__st13'
      d='M108.7 230.4l-.5 3-.4 2.3-.8 4.1-7.4 5.2s-.7-.6-1.8-1.5c-3-2.5-9.4-7.5-14.1-10.3-1.2-.8-2.5-1.4-3.9-1.8l28.9-1z'
    />
    <path
      className='about_01_svg__st4'
      d='M108.7 230.4l-.5 3c-1.6.1-3.3 0-4.9-.2l-17.3.2c-.8 0-1.6-.1-2.3-.2-1.2-.8-2.5-1.4-3.9-1.8l28.9-1z'
    />
    <path
      className='about_01_svg__st14'
      d='M103.3 232.7s8.6 1.5 9.1-2.2-4.4-5.6-8.6-5.9c-4.2-.3-29.3 1.5-29.3 1.5s-4.4 5.9 11.6 6.9l17.2-.3z'
    />
    <path
      className='about_01_svg__st4'
      d='M108.7 230.4l-.5 3-.4 2.3-.8 4.1-7.4 5.2s-.7-.6-1.8-1.5c-3-2.5-9.4-7.5-14.1-10.3-1.2-.8-2.5-1.4-3.9-1.8l28.9-1z'
    />
    <path
      className='about_01_svg__st4'
      d='M108.7 230.4l-.5 3c-1.6.1-3.3 0-4.9-.2l-17.3.2c-.8 0-1.6-.1-2.3-.2-1.2-.8-2.5-1.4-3.9-1.8l28.9-1z'
    />
    <path
      className='about_01_svg__st4'
      d='M103.3 232.7s8.6 1.5 9.1-2.2-4.4-5.6-8.6-5.9c-4.2-.3-29.3 1.5-29.3 1.5s-4.4 5.9 11.6 6.9l17.2-.3z'
    />
    <path className='about_01_svg__st15' d='M77 111.2h45.1v53.4H77z' />
    <path className='about_01_svg__st4' d='M77 111.2h45.1v53.4H77z' />
    <path
      className='about_01_svg__st13'
      d='M24.2 202.7c-.7.2-1.7.9-2.7 1.9-4.6 4.5-11 15.1-11 15.1-8.3-9.9.9-17.7 4.8-20.4 1-.7 1.6-1 1.6-1l7.3 4.4zM108.2 128.1s2.2 15.5 9.9 19.2-9.9 10.6-9.9 10.6L84.3 149s13.5-4.4 10.8-17.5l13.1-3.4zM82.6 221.4l-.5 3-.4 2.3-.8 4.1-7.4 5.2s-.7-.6-1.8-1.5c-3-2.5-9.4-7.5-14.1-10.3-1.2-.8-2.5-1.4-3.9-1.8l28.9-1z'
    />
    <path
      className='about_01_svg__st4'
      d='M82.6 221.4l-.5 3c-1.6.1-3.3 0-4.9-.2l-17.2.2c-.8 0-1.6-.1-2.3-.2-1.2-.8-2.5-1.4-3.9-1.8l28.8-1z'
    />
    <path
      className='about_01_svg__st14'
      d='M77.1 223.7s8.6 1.5 9.1-2.2-4.4-5.7-8.6-5.9c-4.2-.2-29.3 1.5-29.3 1.5s-4.4 5.9 11.6 6.9l17.2-.3z'
    />
    <path className='about_01_svg__st4' d='M81.6 226.7l-.8 4.1-7.4 5.2s-.7-.6-1.8-1.5l9.8-8 .2.2z' />
    <path
      className='about_01_svg__st14'
      d='M130.4 283c-.3-.2-.7-.3-1-.5l-.9-1.1-11.9-15.1v.3c-.1 2.5-.9 22.5-7.6 24.4-7.1 2-15.8 2.5-19.7-10.8S78.1 237.7 70.7 236l11-9s7.3 8.5 10.2 16.6c-.1-.5-5.1-23.9-1-28.1h36s5.5 15.8 9.4 23.6c3 6.1 7.4 20.5 10.3 30.2 2.8 9.7-7.2 18.2-16.2 13.7z'
    />
    <path
      className='about_01_svg__st4'
      d='M129.4 220c-5.5 3.3-29.9 1.9-37.9 1.4l-2.2-.2c-.2 0 0-.1 0-.1l.2-2.2 5.9-15 31.6-18.3c-.6 1.9-.9 4.9-.8 8.3.3 10.7 3.2 26.1 3.2 26.1z'
    />
    <path
      d='M129.4 219.2c-5.5 3.3-29.9 1.9-37.9 1.4l-2.2-.2H89l.4-.1 1.9-.5 4.2-16.7 31.6-18.3c-.6 1.9-.9 4.9-.8 8.3.2 10.8 3.1 26.1 3.1 26.1z'
      fill='#f2f1f2'
    />
    <path
      className='about_01_svg__st4'
      d='M126.3 193c-6.4 1.5-20.6 5.8-26 16.3-3.6 7-6.7 10-8.8 11.3l-2.2-.2v-.1l1.9-.5 4.2-16.7 31.6-18.3c-.5 1.9-.7 4.8-.7 8.2zM16.8 198.3l7.4 4.4c-.7.2-1.7.9-2.7 1.9-.6-1.8-4.5-4.3-6.2-5.4.9-.6 1.5-.9 1.5-.9zM88 220.8c-.2.1-.4.1-.5-.1.2.1.4.1.5.1z'
    />
    <path
      d='M104.7 146.3s-6.7.7-11.3-4.2c0 0-19.7 5.7-26.4 18.5-6.7 12.8-52 37.9-52 37.9s7.9 4.4 7.1 6.7L52 188s10.6-7.6 13.5-9.4 19.2-12.3 22.7 16.5c3.4 28.8 0 25.4 0 25.4s4.9 1.7 12.1-12.1 29.3-17 29.3-17l1.2-14.5s4.4-9.6 1-13.6c-1-1.2-1.7-2.6-2-4.2-.6-3.7 0-9.8 7.9-14.9 12.1-7.9 16.7-14.3 16.7-14.3s.2-4.7-7.1-6.9c-7.4-2.2-37.4-14.3-37.4-14.3l-1 4.2 23.6 16.7s-11.8 9.6-21.2 9.4c.1-.1 1.8 7.1-6.6 7.3z'
      fill='#565388'
    />
    <path
      className='about_01_svg__st4'
      d='M109.3 134.9c-.6-2-1-4-1.4-6l-13.1 3.5c.6 2.5.5 5.1-.3 7.5 5.5 1.4 11.3-.6 14.8-5z'
    />
    <circle className='about_01_svg__st13' cx={98.4} cy={125.5} r={14} />
    <path
      className='about_01_svg__st4'
      d='M105.9 93.6c3.9.4 7.6 2.1 11.4 3 1 .3 2 .3 3 .1 1.5-.5 2.7-2 4.2-2 .9 0 1.8.7 2.6 1.3 1.3 1 2.4 2 3.5 3.2 2.6 2.9 4.3 6.7 6.7 9.9 2.4 3.1 6.2 5.6 9.6 4.1-2.3 9.1-10 16-18.2 16.6-3.3.2-6.7-.5-9.9.5-.8.3-1.6.5-2.5.6-1.8 0-3.3-1.7-4.5-3.3-2.7-3.9-4.8-8.2-7.9-11.7-1-1.2-2.2-2.3-3.6-2.5-1.4-.2-2.7.5-4 1.1-2.1 1.1-4.2 2.2-5.8 4-1.6 1.8-2.6 4.6-2 7.1.4 1.3 1.2 2.6 1.2 4 0 2.2-1.9 3.9-2.3 6-.1.9-.1 1.8-.1 2.8-.2 3-2.1 5.4-4.2 7.3-.8.7-1.7 1.5-1.9 2.6-.2.8 0 1.6-.1 2.4-.3 1.9-2.2 2.9-3.9 3.2-2 .3-4 .1-5.9-.7 2.4-1.1 3.9-4.4 3.3-7.3-.8-4-4.8-6.9-4.4-10.9a15 15 0 011.1-3.6c.9-2.2 1.5-4.5 1.9-6.8.4-1.8.4-3.7-.1-5.4-.5-1.6-1.6-2.8-2.5-4.1-1.2-1.8-2.2-3.8-2.8-5.8-.2-.6-.3-1.3-.3-1.9.2-.8.6-1.5 1.2-2 2.2-2.3 4.6-4.7 7.5-5.4 1.4-.2 2.8-.3 4.2-.2 2.5.1 4.9 0 7.4-.3 2-.2 5.4-.2 7.1-1.5 1.6-1.2 2.5-2.8 4.5-3.5 2.2-1 4.4-1.2 6.5-.9zM113.7 238.6s8.1 16 5.7 20.9M128.5 281.4l-11.9-15.1v.3s-.7-4.9 1.6-3.2c5 3.6 12.9 9.5 13.1 10.5s-1.7 5-2.8 7.5z'
    />
    <g className='about_01_svg__st6'>
      <path d='M111.6 138.9s1 4.2-2.2 6.2c5-.9 5.2-4.7 5.1-6.4-1 .2-1.9.2-2.9.2zM18 197.6s45.3-25.1 52-37.9c4.8-9.2 16.3-14.7 22.4-17.1-4.5 1.5-19.5 7.2-25.1 18.1-6.7 12.8-52 37.9-52 37.9l2.7-1zM120.4 137c7.7-2.4 15.1-8.4 15.1-8.4l-23.6-16.7.5-2.3-2.3-.9-1 4.2 23.6 16.7c-3.7 3-7.8 5.4-12.3 7.4zM120.6 193.7c3-1 6.1-1.8 9.3-2.3v-.5c-3.2.7-6.3 1.7-9.3 2.8zM91.9 219.5h-.2c-.2.1-.4.1-.5-.1.2 0 .3.1.5.1.8-.5 2-4.1-.5-25.4-2.7-22.7-13.4-20.9-19.2-18.3 6-1.6 14.2-.2 16.5 19.3 2.5 21.3 1.3 25 .5 25.4 1 0 2.1-.4 2.9-1zM89 220.5c-.2 0-.4 0-.5-.1.1.2.3.2.5.1z' />
    </g>
    <path
      className='about_01_svg__st15'
      d='M105.9 93.1c3.9.4 7.6 2.1 11.4 3 1 .3 2 .3 3 .1 1.5-.5 2.7-2 4.2-2 .9 0 1.8.7 2.6 1.3 1.3 1 2.4 2 3.5 3.2 2.6 2.9 4.3 6.7 6.7 9.9 2.4 3.1 6.2 5.6 9.6 4.1-2.3 9.1-10 16-18.2 16.6-3.3.2-6.7-.5-9.9.5-.8.3-1.6.5-2.5.6-1.8 0-3.3-1.7-4.5-3.3-2.7-3.9-4.8-8.2-7.9-11.7-1-1.2-2.2-2.3-3.6-2.5-1.4-.2-2.7.5-4 1.1-2.1 1.1-4.2 2.2-5.8 4-1.6 1.8-2.6 4.6-2 7.1.4 1.3 1.2 2.6 1.2 4 0 2.2-1.9 3.9-2.3 6-.1.9-.1 1.8-.1 2.8-.2 3-2.1 5.4-4.2 7.3-.8.7-1.7 1.5-1.9 2.6-.2.8 0 1.6-.1 2.4-.3 1.9-2.2 2.9-3.9 3.2-2 .3-4 .1-5.9-.7 2.4-1.1 3.9-4.4 3.3-7.3-.8-4-4.8-6.9-4.4-10.9a15 15 0 011.1-3.6c.9-2.2 1.5-4.5 1.9-6.8.4-1.8.4-3.7-.1-5.4-.5-1.6-1.6-2.8-2.5-4.1-1.2-1.8-2.2-3.8-2.8-5.8-.2-.6-.3-1.3-.3-1.9.2-.8.6-1.5 1.2-2 2.2-2.3 4.6-4.7 7.5-5.4 1.4-.2 2.8-.3 4.2-.2 2.5.1 4.9 0 7.4-.3 2-.2 5.4-.2 7.1-1.5 1.6-1.2 2.5-2.8 4.5-3.5 2.2-1 4.4-1.2 6.5-.9z'
    />
    <linearGradient
      id='about_01_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={567.879}
      y1={-8.691}
      x2={567.879}
      y2={237.431}
      gradientTransform='matrix(1 0 0 -1 -125.1 283.24)'
    >
      <stop offset={0} stopColor='gray' stopOpacity={0.25} />
      <stop offset={0.54} stopColor='gray' stopOpacity={0.12} />
      <stop offset={1} stopColor='gray' stopOpacity={0.1} />
    </linearGradient>
    <path
      d='M506.8 61.6v-.2-.2h-.1v-.5c-.2-5.6-8-17.5-11.1-14.1-2.7 3.1 1.4 10.8 3.5 14.3l.2.3-.1-.1s-8.7 25.8-9.7 26.8-8.1 21.3-17.5 23.6c-1.9.4-3.4.8-4.7 1.2l.1-.3c.6-2 1.3-3.8 1.7-6 .3-2.2 0-4.9-1.1-5.9-.5-.5-1.2-.6-1.7-1.5-.3-.7-.4-1.5-.5-2.3l-.8-9.8.6-.2c3.4-1.1 6.6-3.6 7-7.1-.1 0-.2.1-.4.1 0-.2.1-.4.1-.6-2.8 1.1-5.4-1.8-8.2-3.1-4.5-2.2-9.6-.1-14.2.4-5.2.6-10.2-1.6-15.6-1.2-2.9.2-6.2 2.2-6 5.1.1 1.6 1.2 2.9 1.7 4.4 1.2 3.7-1.4 7.3-3.7 10.4-1.5 2-2.9 4.5-3.2 7-5.2-5.4-10.8-11.7-11.5-14.1l-15.1-25.8-.9.9-.3-.9c-1.9-5.4-7.4-18.4-14.3-16s2.9 15.8 7.1 21.1l.6.7c-.4.1-.7.1-1.1 0l16.8 28.8s3.1 5.5 5.9 7c0 0 20.9 26.3 21.1 31.6.3 5.3 9.4 19.3 9.4 19.3s3.1 22.8-2.3 26.8H439.5c-.3.2-.5.5-.8.8-4 4.4-9 13.8-2.1 29.8 10.2 23.6 11.7 32.8 11.7 32.8s-19.3 1-23.2 1.8c-3.4.7-16.5 1.1-19.5 1.2-1.1-1.2-4.2-4-8.5 0-5.3 5-9.2 21.8-9.2 21.8s-2 13.5 4.8 10c0 0 8.1-11.3 10.4-11.8 1.1-.2 2.3-1.9 3.3-3.5.1 0 .3-.1.6-.3 1.7-.7 6.4-2.3 10-1.5 2 .4 10.6.5 19.7.5-2 4.9-5.7 17.5-5.7 17.5s-1.5 11.8 3.5 11.3c3.6-.4 9.2-9.6 12.1-14.9 2.1-1.5 8.1-7.1 12.6-6.7l30-11.5s7.1-4 3.3-17.3c-3-10.6-11.6-32.2-15-40.6l-.1-.2c-.8-2-1.3-3.2-1.3-3.2-1.4-3.8-2.3-7.8-2.7-11.9 0-.3-.1-.6-.1-1 .4-.2.8-.4 1.2-.7 0 0-2.8-28.3 0-31.3v-.2l.1-.1.1-.2c.2-.2.3-.4.5-.7 1.6-2.3 4.6-7.7 3.7-14 0-.3-.1-.6-.1-.8-.3-1.7-.9-3.4-1.7-4.9-.1-.6-.2-1.3-.2-1.9v-1.3-.5c0-.3 0-.6.1-.9v-.1c0-.1 0-.2.1-.3v-.1c0-.1 0-.2.1-.3v-.1c0-.1.1-.3.1-.4v-.1c0-.1.1-.2.1-.4l.1-.1.1-.3.1-.1c0-.1.1-.2.2-.3v-.1c.1-.1.1-.3.2-.4l.1-.1.2-.3.1-.1.2-.3.1-.1.3-.3c3.3-3 18.1-25.3 18.1-25.3s3.6-5 3.6-8.3c.4-.3 6.7-21.2 6-27.6zm-39.6 187.3c-.4-3.4-.7-7.7-1.1-12.1l5.8 9s-.5 2.7-4.7 3.1z'
      fill='url(#about_01_svg__SVGID_4_)'
    />
    <path
      className='about_01_svg__st19'
      d='M407.2 247s-3.4-5.4-8.6-.5c-5.2 5-8.9 21.4-8.9 21.4s-2 13.3 4.7 9.9c0 0 7.9-11.1 10.1-11.6 2.2-.5 4.7-6.7 4.7-6.7l-2-12.5zM444.1 260.3s-4.7-2.2-6.2-.5c-1.5 1.7-6.4 19-6.4 19s-1.5 11.3 3.4 10.8 13.5-18.2 13.5-18.2l-4.3-11.1z'
    />
    <path
      className='about_01_svg__st20'
      d='M444.1 260.3s-4.7-2.2-6.2-.5c-1.5 1.7-6.4 19-6.4 19s-1.5 11.3 3.4 10.8 13.5-18.2 13.5-18.2l-4.3-11.1z'
    />
    <path
      className='about_01_svg__st21'
      d='M460.4 226.8l10.6 16.5s-.7 3.7-7.1 3l-22 13.7s-1.9 16.8 4.2 15.3c1-.2 7.9-7.4 12.8-6.9l29.1-11.3s6.9-3.9 3.2-17c-3.7-13-15.9-43.3-15.9-43.3L456 207.6l4.4 19.2z'
    />
    <path
      className='about_01_svg__st20'
      d='M460.4 226.8l10.6 16.5s-.7 3.7-7.1 3l-22 13.7s-1.9 16.8 4.2 15.3c1-.2 7.9-7.4 12.8-6.9l29.1-11.3s6.9-3.9 3.2-17c-3.7-13-15.9-43.3-15.9-43.3L456 207.6l4.4 19.2z'
    />
    <path
      className='about_01_svg__st21'
      d='M467 251.2c1.5 9.4-6.9 10.1-6.9 10.1s-37.6.7-42.1-.3c-4.4-1-10.3 1.7-10.3 1.7-7.6-4.7-1.5-16.3-1.5-16.3s15.8-.5 19.5-1.2c3.7-.7 22.4-1.7 22.4-1.7s-1.5-9.1-11.3-32.3c-6.7-15.7-1.8-25 2-29.3 1-1.1 2.1-2.1 3.4-3l30.3 1.5c0 1.5 0 3.1.2 4.6.5 5.2 1.9 10.3 4 15.1-1.7 0-12.8 10.3-12.8 10.3s1.7 31.4 3.1 40.8z'
    />
    <path
      className='about_01_svg__st4'
      d='M408.4 247.5s15.8-.5 19.5-1.2c3.7-.7 22.4-1.7 22.4-1.7s-1.5-9.1-11.3-32.3c-6.7-15.7-1.8-25 2-29.3 1-1.1 2.1-2.1 3.4-3l28.1 1.4v-.9l-30.3-1.5c-1.3.8-2.4 1.8-3.4 3-3.8 4.3-8.7 13.6-2 29.3 9.9 23.1 11.3 32.2 11.3 32.2s-18.7 1-22.4 1.7-19.5 1.2-19.5 1.2-6.2 11.6 1.5 16.3l.6-.3c-5.1-5.1.1-14.9.1-14.9z'
    />
    <path className='about_01_svg__st19' d='M438.2 88.6h15.3v38.7h-15.3z' />
    <path className='about_01_svg__st4' d='M438.2 88.6h15.3v38.7h-15.3z' />
    <path
      className='about_01_svg__st19'
      d='M462.2 83c.5.1 1.1.2 1.5.9.2.6.3 1.2.4 1.8l1 11.9c0 .8.2 1.6.4 2.3.4.8 1.1.9 1.6 1.4 1.1 1.1 1.4 3.7 1.1 5.8-.3 2.1-1 4-1.6 5.9-.8 2.8-1.4 5.9-2.5 8.3-1.2 2.3-3.3 3.6-4.6 1.7-1.4-2-1.2-6-1.4-9.2-.4-5.4-2.5-10.1-2.7-15.6-.1-4.4 1.1-8.6 1.8-12.8.5-2.9.6-6.4 1.3-9 .6 3.1 1.8 6 3.7 6.6z'
    />
    <path
      className='about_01_svg__st4'
      d='M462.2 83c.5.1 1.1.2 1.5.9.2.6.3 1.2.4 1.8l1 11.9c0 .8.2 1.6.4 2.3.4.8 1.1.9 1.6 1.4 1.1 1.1 1.4 3.7 1.1 5.8-.3 2.1-1 4-1.6 5.9-.8 2.8-1.4 5.9-2.5 8.3-1.2 2.3-3.3 3.6-4.6 1.7-1.4-2-1.2-6-1.4-9.2-.4-5.4-2.5-10.1-2.7-15.6-.1-4.4 1.1-8.6 1.8-12.8.5-2.9.6-6.4 1.3-9 .6 3.1 1.8 6 3.7 6.6z'
    />
    <path
      className='about_01_svg__st22'
      d='M397.6 66l-5.9 4.9s-.7-.8-1.7-2.2c-4.1-5.2-13.5-18.4-6.9-20.7 6.7-2.4 11.9 10.5 13.8 15.8.4 1.3.7 2.2.7 2.2zM504.2 64.2c-2.5 3.7-5.9 0-5.9 0s-.4-.7-1.1-1.7c-2-3.4-6-11-3.4-14 3-3.4 10.5 8.3 10.7 13.8.2.7.1 1.4-.3 1.9zM443.6 104.1s4.9 15.8 3.4 20.4c-1.5 4.7 10.6 10.1 10.6 10.1h13.8s4.9-10.1 3.7-13.1-11.8-5.2-11.8-5.2-6.2 0-5.9-16.5l-13.8 4.3z'
    />
    <path
      className='about_01_svg__st4'
      d='M397.6 66l-5.9 4.9s-.7-.8-1.7-2.2c2.1-.4 5.5-3.5 6.9-5 .4 1.4.7 2.3.7 2.3zM504.2 64.2c-2.5 3.7-5.9 0-5.9 0s-.4-.7-1.1-1.7l.1-.2c1.6 1.1 6.6.2 7.3 0 .1.7 0 1.3-.4 1.9zM472.6 185.1c-7.6 3.5-32.4-2.7-33.9-3.1 1-1.1 2.1-2.1 3.4-3l30.3 1.5c0 1.5.1 3 .2 4.6z'
    />
    <path
      d='M446.8 117.7s-5.9 2-14.3-5.7c0 0-18.7-18.5-20-22.9L398 63.7s-6.2 6.7-8.6 5.7l16.3 28.3s3 5.4 5.7 6.9c0 0 20.2 25.9 20.4 31 .2 5.2 9.1 19 9.1 19s3 22.4-2.2 26.4c0 0 28.8 7.4 35 2.5 0 0-2.7-27.8 0-30.8 0 0 8.4-9.9 2.7-20.7 0 0-1.2-5.9 2-8.9s17.5-24.9 17.5-24.9 3.4-4.9 3.4-8.1c0 0 6.4-21.7 5.4-27.3 0 0-5.7 1.2-7.4 0 0 0-8.4 25.4-9.4 26.4s-7.9 20.9-17 23.2c-9.1 2.2-10.6 3.2-10.6 3.2s17.7 11.1 9.1 16c-8.5 4.8-22.6-13.9-22.6-13.9z'
      fill='#ffaac4'
    />
    <path
      className='about_01_svg__st4'
      d='M458 107.9c-.3-2.3-.4-4.7-.4-7l-13.8 4.2c.4.9.7 1.9.9 2.8 1.6.7 3.7 2.3 5.5 2.3 2.9.2 5.6-.6 7.8-2.3z'
    />
    <circle className='about_01_svg__st22' cx={450} cy={96.8} r={12.8} />
    <path
      className='about_01_svg__st4'
      d='M434.8 77c-2.8.2-6 2.2-5.8 5 .1 1.6 1.1 2.9 1.6 4.4 1.1 3.6-1.4 7.2-3.6 10.3s-4.2 7.1-2.4 10.4c.5.8 1.1 1.5 1.7 2.3 2.3 3.1 2.3 7.3 3.3 11.1 2.7 10 12.8 16.1 22.9 13.9-1.8-2.3-2.7-5.3-2.6-8.2.1-2.2.8-4.4 0-6.4-1.1-2.6-4.2-3.6-5.9-5.8-1.6-2.2-1.5-5.3-.7-8s2.1-5.1 2.8-7.9l.6-2.4c1.1-3.2 4.5-5.1 7.9-5.9 3.3-.8 6.8-.9 10.1-1.9 3.2-1.1 6.4-3.5 6.8-6.9-2.7 1.1-5.3-1.8-7.9-3.1-4.3-2.2-9.3-.1-13.8.4-5 .5-9.8-1.7-15-1.3z'
    />
    <path
      className='about_01_svg__st19'
      d='M434.6 76.5c-2.8.2-6 2.2-5.8 5 .1 1.6 1.1 2.9 1.6 4.4 1.1 3.6-1.4 7.2-3.6 10.3s-4.2 7.1-2.4 10.4c.5.8 1.1 1.5 1.7 2.3 2.3 3.1 2.3 7.3 3.3 11.1 2.7 10 12.8 16.1 22.9 13.9-1.8-2.3-2.7-5.3-2.6-8.2.1-2.2.8-4.4 0-6.4-1.1-2.6-4.2-3.6-5.9-5.8-1.6-2.2-1.5-5.3-.7-8s2.1-5.1 2.8-7.9l.6-2.4c1.1-3.2 4.5-5.1 7.9-5.9s6.8-.9 10.1-1.9c3.2-1.1 6.4-3.5 6.8-6.9-2.7 1.1-5.3-1.8-7.9-3.1-4.3-2.2-9.3-.1-13.8.4-5 .5-9.9-1.7-15-1.3z'
    />
    <path className='about_01_svg__st4' d='M462.7 125.2s5.1 4.6 6.1 6.6M473.8 152.5s-6.4 0-9.4.7-10.1 0-10.1 0' />
    <g className='about_01_svg__st6'>
      <path d='M469.5 131.5c-.6.3-1.1.5-1.8.7 1.6.4 3.3.1 4.7-.7 8.6-4.9-9.1-16-9.1-16h-3c.1 0 17.8 11.1 9.2 16zM504.8 63.2c0-.2 0-.3-.1-.4-1 .2-2 .4-3.1.5 1.1.1 2.1 0 3.2-.1zM441.7 181c5.2-3.9 2.2-26.4 2.2-26.4s-8.9-13.8-9.1-19c-.2-5.2-20.4-31-20.4-31-2.7-1.5-5.7-6.9-5.7-6.9S392 70.4 389.5 69.4l16.3 28.3s3 5.4 5.7 6.9c0 0 20.2 25.9 20.4 31 .2 5.2 9.1 19 9.1 19s3 22.4-2.2 26.4c0 0 17.7 4.5 28.3 4.1-10.7-.3-25.4-4.1-25.4-4.1zM447 117.9l-.1-.2c-.2 0-.3.1-.5.1.2 0 .4.1.6.1z' />
    </g>
    <g className='about_01_svg__st6'>
      <path d='M504.7 62.7h-.2C504.2 70.3 499 88 499 88c0 3.2-3.4 8.1-3.4 8.1S481.3 118 478.1 121c-3.2 3-2 8.9-2 8.9v.1c-.1-2 .1-5.1 2.2-7 3.2-3 17.5-24.9 17.5-24.9s3.4-4.9 3.4-8.1c.1.1 6.5-21.6 5.5-27.3zM473.4 150.7c.4 2.1.1-.1.2 2 0 0 6-7.1 4.4-15.9.6 7.8-4.6 13.9-4.6 13.9z' />
    </g>
    <ellipse className='about_01_svg__st1' cx={286.9} cy={375.2} rx={258.6} ry={24.8} />
    <path
      className='about_01_svg__st24'
      d='M186.7 350s-8.8-29.9 1.7-51.9c4.4-9.2 5.8-19.6 3.8-29.6-1-4.8-2.4-9.4-4.2-13.9'
    />
    <path
      className='about_01_svg__st0'
      d='M194.5 240.6c0 3.7-6.7 14.3-6.7 14.3s-6.7-10.6-6.7-14.3c-.3-3.7 2.5-6.9 6.1-7.1 3.7-.3 6.9 2.5 7.1 6.1.1.4.1.7.2 1zM205.4 260.9c-2 3.1-13.3 8.4-13.3 8.4s.2-12.5 2.2-15.6c1.8-3.2 5.8-4.4 9-2.6 3.2 1.8 4.4 5.8 2.6 9-.1.3-.3.6-.5.8zM204.9 295.6c-3.3 1.7-15.7.5-15.7.5s6.5-10.7 9.8-12.4c3.1-1.9 7.2-.9 9.1 2.3 1.9 3.1.9 7.2-2.3 9.1-.3.2-.5.4-.9.5zM198.7 321.8c-2.9 2.2-15.4 3.2-15.4 3.2s4.6-11.7 7.5-13.8c3.1-1.9 7.2-.9 9.1 2.2 1.7 2.7 1.1 6.2-1.2 8.4zM178.1 270c2.6 2.6 14.9 5.2 14.9 5.2s-3-12.2-5.6-14.7c-2.4-2.7-6.7-3-9.4-.5-2.7 2.4-3 6.7-.5 9.4l.6.6zM171.2 300.7c3.3 1.7 15.7.5 15.7.5s-6.5-10.7-9.7-12.3c-3.4-1.4-7.3.2-8.7 3.6-1.3 3-.1 6.5 2.7 8.2zM168.5 331.5c2.9 2.2 15.4 3.2 15.4 3.2s-4.6-11.7-7.5-13.8c-2.8-2.4-7-2.2-9.4.6-2.4 2.8-2.2 7 .6 9.4.3.1.6.4.9.6z'
    />
    <path
      className='about_01_svg__st1'
      d='M194.5 240.6c0 3.7-6.7 14.3-6.7 14.3s-6.7-10.6-6.7-14.3c-.3-3.7 2.5-6.9 6.1-7.1 3.7-.3 6.9 2.5 7.1 6.1.1.4.1.7.2 1zM205.4 260.9c-2 3.1-13.3 8.4-13.3 8.4s.2-12.5 2.2-15.6c1.8-3.2 5.8-4.4 9-2.6 3.2 1.8 4.4 5.8 2.6 9-.1.3-.3.6-.5.8zM204.9 295.6c-3.3 1.7-15.7.5-15.7.5s6.5-10.7 9.8-12.4c3.1-1.9 7.2-.9 9.1 2.3 1.9 3.1.9 7.2-2.3 9.1-.3.2-.5.4-.9.5zM198.7 321.8c-2.9 2.2-15.4 3.2-15.4 3.2s4.6-11.7 7.5-13.8c3.1-1.9 7.2-.9 9.1 2.2 1.7 2.7 1.1 6.2-1.2 8.4zM178.1 270c2.6 2.6 14.9 5.2 14.9 5.2s-3-12.2-5.6-14.7c-2.4-2.7-6.7-3-9.4-.5-2.7 2.4-3 6.7-.5 9.4l.6.6zM171.2 300.7c3.3 1.7 15.7.5 15.7.5s-6.5-10.7-9.7-12.3c-3.4-1.4-7.3.2-8.7 3.6-1.3 3-.1 6.5 2.7 8.2zM168.5 331.5c2.9 2.2 15.4 3.2 15.4 3.2s-4.6-11.7-7.5-13.8c-2.8-2.4-7-2.2-9.4.6-2.4 2.8-2.2 7 .6 9.4.3.1.6.4.9.6z'
    />
    <path
      className='about_01_svg__st24'
      d='M364.8 350.4s-8.8-29.9 1.7-51.9c4.4-9.2 5.8-19.6 3.8-29.6-1-4.8-2.4-9.4-4.2-13.9'
    />
    <path
      className='about_01_svg__st0'
      d='M372.6 241.1c0 3.7-6.7 14.3-6.7 14.3s-6.7-10.6-6.7-14.3c.3-3.7 3.5-6.4 7.1-6.1 3.4.2 6 2.8 6.3 6.1zM383.6 261.3c-2 3.1-13.3 8.4-13.3 8.4s.2-12.5 2.2-15.6c1.8-3.2 5.8-4.4 9-2.6s4.4 5.8 2.6 9c-.2.3-.3.6-.5.8zM383.1 296c-3.3 1.7-15.7.5-15.7.5s6.5-10.7 9.8-12.4c3.1-1.9 7.2-.9 9.1 2.2s.9 7.2-2.2 9.1c-.3.3-.7.5-1 .6zM376.8 322.2c-2.9 2.2-15.4 3.2-15.4 3.2s4.6-11.7 7.5-13.8c3.1-1.9 7.2-.9 9.1 2.2 1.7 2.7 1.2 6.3-1.2 8.4zM356.3 270.4c2.6 2.6 14.9 5.2 14.9 5.2s-3-12.2-5.6-14.7c-2.4-2.7-6.7-3-9.4-.5-2.7 2.4-3 6.7-.5 9.4.1.2.4.4.6.6zM349.4 301.1c3.3 1.7 15.7.5 15.7.5s-6.5-10.7-9.8-12.4c-3.4-1.4-7.3.2-8.7 3.6-1.2 3.1-.1 6.6 2.8 8.3zM346.7 331.9c2.9 2.2 15.4 3.2 15.4 3.2s-4.6-11.7-7.5-13.8c-3.1-1.9-7.2-.9-9.1 2.2-1.7 2.7-1.2 6.3 1.2 8.4z'
    />
    <path
      className='about_01_svg__st1'
      d='M372.6 241.1c0 3.7-6.7 14.3-6.7 14.3s-6.7-10.6-6.7-14.3c.3-3.7 3.5-6.4 7.1-6.1 3.4.2 6 2.8 6.3 6.1zM383.6 261.3c-2 3.1-13.3 8.4-13.3 8.4s.2-12.5 2.2-15.6c1.8-3.2 5.8-4.4 9-2.6s4.4 5.8 2.6 9c-.2.3-.3.6-.5.8zM383.1 296c-3.3 1.7-15.7.5-15.7.5s6.5-10.7 9.8-12.4c3.1-1.9 7.2-.9 9.1 2.2s.9 7.2-2.2 9.1c-.3.3-.7.5-1 .6zM376.8 322.2c-2.9 2.2-15.4 3.2-15.4 3.2s4.6-11.7 7.5-13.8c3.1-1.9 7.2-.9 9.1 2.2 1.7 2.7 1.2 6.3-1.2 8.4zM356.3 270.4c2.6 2.6 14.9 5.2 14.9 5.2s-3-12.2-5.6-14.7c-2.4-2.7-6.7-3-9.4-.5-2.7 2.4-3 6.7-.5 9.4.1.2.4.4.6.6zM349.4 301.1c3.3 1.7 15.7.5 15.7.5s-6.5-10.7-9.8-12.4c-3.4-1.4-7.3.2-8.7 3.6-1.2 3.1-.1 6.6 2.8 8.3zM346.7 331.9c2.9 2.2 15.4 3.2 15.4 3.2s-4.6-11.7-7.5-13.8c-3.1-1.9-7.2-.9-9.1 2.2-1.7 2.7-1.2 6.3 1.2 8.4z'
    />
    <path className='about_01_svg__st25' d='M33.5 311.3s10.6 20.8 2 32.1c-8.6 11.4.8 42.3.8 42.3' />
    <path
      className='about_01_svg__st0'
      d='M36.6 304.8c0 4-2.9 7.3-2.9 7.3s-2.9-3.2-2.9-7.3c0-4 2.9-7.3 2.9-7.3s2.9 3.3 2.9 7.3z'
    />
    <path className='about_01_svg__st25' d='M39 311.3s-10.6 20.8-2 32.1c8.6 11.4-.8 42.3-.8 42.3' />
    <path
      className='about_01_svg__st0'
      d='M35.8 304.8c0 4 2.9 7.3 2.9 7.3s2.9-3.2 2.9-7.3c0-4-2.9-7.3-2.9-7.3s-2.9 3.3-2.9 7.3z'
    />
    <path
      className='about_01_svg__st4'
      d='M35.8 304.8c0 4 2.9 7.3 2.9 7.3s2.9-3.2 2.9-7.3c0-4-2.9-7.3-2.9-7.3s-2.9 3.3-2.9 7.3z'
    />
    <path className='about_01_svg__st25' d='M43.3 311.3s10.6 20.8 2 32.1c-8.6 11.4.8 42.3.8 42.3' />
    <path
      className='about_01_svg__st0'
      d='M46.4 304.8c0 4-2.9 7.3-2.9 7.3s-2.9-3.2-2.9-7.3c0-4 2.9-7.3 2.9-7.3s2.9 3.3 2.9 7.3zM44.8 321.4c-2.8 2.9-7.1 3.2-7.1 3.2s.1-4.4 2.9-7.3c2.8-2.9 7.1-3.2 7.1-3.2s-.2 4.4-2.9 7.3zM45 335.7c-2.8 2.9-7.1 3.2-7.1 3.2s.1-4.4 2.9-7.3c2.8-2.9 7.1-3.2 7.1-3.2s-.1 4.5-2.9 7.3zM30 343.6c2.6 3 2.5 7.4 2.5 7.4s-4.3-.5-7-3.5-2.5-7.4-2.5-7.4 4.4.5 7 3.5zM32.4 313.1c3.3 2.2 4.4 6.5 4.4 6.5s-4.3.7-7.7-1.6-4.4-6.5-4.4-6.5 4.4-.6 7.7 1.6z'
    />
    <path
      className='about_01_svg__st4'
      d='M32.4 313.1c3.3 2.2 4.4 6.5 4.4 6.5s-4.3.7-7.7-1.6-4.4-6.5-4.4-6.5 4.4-.6 7.7 1.6z'
    />
    <path className='about_01_svg__st25' d='M48.8 311.3s-10.6 20.8-2 32.1c8.6 11.4-.8 42.3-.8 42.3' />
    <path
      className='about_01_svg__st0'
      d='M45.6 304.8c0 4 2.9 7.3 2.9 7.3s2.9-3.2 2.9-7.3c0-4-2.9-7.3-2.9-7.3s-2.9 3.3-2.9 7.3z'
    />
    <path
      className='about_01_svg__st4'
      d='M45.6 304.8c0 4 2.9 7.3 2.9 7.3s2.9-3.2 2.9-7.3c0-4-2.9-7.3-2.9-7.3s-2.9 3.3-2.9 7.3z'
    />
    <path
      className='about_01_svg__st0'
      d='M46.5 321.7c-2.8 2.9-3 7.3-3 7.3s4.4-.2 7.2-3.1 3-7.3 3-7.3-4.4.3-7.2 3.1z'
    />
    <path
      className='about_01_svg__st4'
      d='M46.5 321.7c-2.8 2.9-3 7.3-3 7.3s4.4-.2 7.2-3.1 3-7.3 3-7.3-4.4.3-7.2 3.1z'
    />
    <path className='about_01_svg__st0' d='M52 341.6c-2.8 2.9-3 7.3-3 7.3s4.4-.2 7.2-3.1 3-7.3 3-7.3-4.4.2-7.2 3.1z' />
    <path
      className='about_01_svg__st25'
      d='M43.9 337.2s6.4-10 15.3-10.3M38.9 331.1s-6.4-10-15.3-10.3M38.1 345.5s6.4-10 15.3-10.3'
    />
    <circle className='about_01_svg__st0' cx={58.9} cy={326.9} r={1.7} />
    <circle className='about_01_svg__st0' cx={23.8} cy={320.9} r={1.7} />
    <path className='about_01_svg__st25' d='M42.8 348.8s-6.4-10-15.3-10.3' />
    <circle className='about_01_svg__st0' cx={27.6} cy={338.6} r={1.7} />
    <circle className='about_01_svg__st0' cx={53.5} cy={335.3} r={1.7} />
    <path
      className='about_01_svg__st26'
      d='M78 373.6s-15.3-3.1-19.4-7.2c-4.1-4.1-22.5-11.3-24.6-6.1-2 5.1-27.6 22.5-10.2 25.6 17.4 3.1 40.9 4.1 46 2 5.1-2 8.2-14.3 8.2-14.3z'
    />
    <path
      className='about_01_svg__st27'
      d='M23.7 384c17.4 3.1 40.9 4.1 46 2 3.9-1.6 6.6-9.1 7.7-12.5l.5.1s-3.1 12.3-8.2 14.3-28.7 1-46-2c-5-.9-6.5-3-5.9-5.6.5 1.7 2.2 3.1 5.9 3.7z'
    />
    <path
      className='about_01_svg__st0'
      d='M543.9 316s5.1 6.7-2.3 16.6c-7.4 10-13.5 18.4-11 24.7 0 0 11.2-18.6 20.3-18.8 9-.3 3-11.4-7-22.5z'
    />
    <path
      className='about_01_svg__st4'
      d='M543.9 316c.4.6.8 1.3 1 2.1 8.9 10.4 13.6 20.2 5.1 20.4-7.9.2-17.5 14.4-19.8 18 .1.3.2.5.3.8 0 0 11.2-18.6 20.3-18.8 9.1-.3 3.1-11.4-6.9-22.5z'
    />
    <path
      className='about_01_svg__st28'
      d='M553.3 324.4c0 2.3-.3 4.2-.6 4.2s-.6-1.9-.6-4.2c0-2.3.3-1.2.6-1.2.4 0 .6-1.1.6 1.2z'
    />
    <path
      className='about_01_svg__st28'
      d='M556.6 327.2c-2 1.1-3.8 1.8-4 1.5-.2-.3 1.4-1.4 3.4-2.5s1.2-.3 1.4 0 1.2-.1-.8 1z'
    />
    <path
      className='about_01_svg__st0'
      d='M517.2 316s-5.1 6.7 2.3 16.6 13.5 18.4 11 24.7c0 0-11.2-18.6-20.3-18.8s-3-11.4 7-22.5z'
    />
    <path
      className='about_01_svg__st4'
      d='M517.2 316c-.4.6-.8 1.3-1 2.1-8.9 10.4-13.6 20.2-5.1 20.4 7.9.2 17.5 14.4 19.8 18-.1.3-.2.5-.3.8 0 0-11.2-18.6-20.3-18.8-9.1-.3-3.1-11.4 6.9-22.5z'
    />
    <path
      className='about_01_svg__st28'
      d='M507.7 324.4c0 2.3.3 4.2.6 4.2s.6-1.9.6-4.2c0-2.3-.3-1.2-.6-1.2s-.6-1.1-.6 1.2z'
    />
    <path
      className='about_01_svg__st28'
      d='M504.5 327.2c2 1.1 3.8 1.8 4 1.5.2-.3-1.4-1.4-3.4-2.5s-1.2-.3-1.4 0-1.2-.1.8 1z'
    />
    <path
      className='about_01_svg__st26'
      d='M509.4 356.4s14.2-.4 18.4-3.5 21.8-6.7 22.9-1.8 21.3 24.3 5.3 24.4c-16 .1-37.2-2.5-41.4-5.1-4.4-2.6-5.2-14-5.2-14z'
    />
    <path
      className='about_01_svg__st27'
      d='M556.2 373.8c-16 .1-37.2-2.5-41.4-5.1-3.2-2-4.5-9.1-5-12.3h-.5s.9 11.4 5.2 14c4.3 2.6 25.4 5.2 41.4 5.1 4.6 0 6.2-1.7 6.1-4.1-.6 1.5-2.3 2.4-5.8 2.4z'
    />
    <path
      d='M110.4 45.1h-3.8c1.5.1 2.7-1.1 2.8-2.5.1-1.5-1.1-2.7-2.5-2.8H75.8c-1.5.1-2.6 1.3-2.5 2.8.1 1.4 1.2 2.5 2.5 2.5h3.8c-1.5.1-2.6 1.3-2.5 2.8.1 1.4 1.2 2.5 2.5 2.5h-5.3c-1.5.1-2.6 1.3-2.5 2.8.1 1.4 1.2 2.5 2.5 2.5h30.8c1.5.1 2.7-1.1 2.8-2.5.1-1.5-1.1-2.7-2.5-2.8h-.3 5.3c1.5-.1 2.6-1.3 2.5-2.8-.1-1.3-1.2-2.4-2.5-2.5z'
      opacity={0.5}
      fill='#dee3f3'
    />
  </svg>
)

export default SvgAbout01
